

export default {
    formatVideoDurationSeconds(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    },

    formatVideoDurationMillis(millis) {
        const seconds = Math.floor(millis / 1000);
        return this.formatVideoDurationSeconds(seconds);
    },

    formatVideoDurationMillisText(millis) {
        if (!millis) {
            return '00:01';
        }

        if (millis <= 20 * 60 * 1000) {
            return this.formatVideoDurationMillis(millis);
        }

        return 'Timeout';
    }
}
