<template>
  <div class="analytics-top-groups">
    <AnalyticsNoData :noData="workshopTopGroups.length === 0"/>

    <ul v-if="workshopTopGroups && workshopTopGroups.length > 0" class="list-group list-group-flush">
      <li v-for="(entry, index) in workshopTopGroupsInPage" :key="'wtg-' + index"
          class="list-group-item d-flex justify-content-between">
        <div>
          <router-link v-if="!entry.deleted_at"
                       :to="{ name: 'reporting-analytics',
                              params: { workshopId: workshopId },
                              query: { gid: entry.group_id, start: startDate, end: endDate }}"
                       :title="'Analytics for ' + entry.name">
            <ScIcon name="chartBarFW" class="me-2"/>
            <span>{{ entry.name }}</span>
          </router-link>

        </div>
        <div class="ms-3 my-auto">{{ fmtInt(entry.count) }}</div>
      </li>
    </ul>
    <div v-if="workshopTopGroups && workshopTopGroups.length > 0" class="row mt-2">
      <div class="col-12">
        <div class="float-end">
          <AnalyticsPaginator :total="workshopTopGroups.length"
                              :currentStart="workshopTopGroupsStart"
                              @update-start="updateWorkshopTopGroupsStart"></AnalyticsPaginator>
        </div>
        <button @click.prevent="exportTopGroupsCsv()" class="btn btn-link text-muted"
                v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
      </div>
    </div>
  </div>
</template>

<script>
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsPaginator from './AnalyticsPaginator.vue';
    import MainAppMixin from '../../MainAppMixin';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';

    export default {
      name: "AnalyticsTopGroups",
      mixins: [ AnalyticsMixin, MainAppMixin ],
      components: { AnalyticsPaginator, ScIcon, AnalyticsNoData },
      props: {
        workshopTopGroups: {type: Array, default: () => []}
      },
      data () {
        return {
          workshopTopGroupsStart: 0
        }
      },
      methods: {
        updateWorkshopTopGroupsStart (v) {
          this.workshopTopGroupsStart = v;
          this.$emit('paginated');
        },
        exportTopGroupsCsv () {
          let csvLinesArr = [];
          for (let entry of this.workshopTopGroups) {
            csvLinesArr.push([
              entry.name,
              this.fmtInt(entry.count)
            ]);
          }
          this.exportCsv('top-groups', ['Group name', 'Slide Views'], csvLinesArr);
        }
      },
      computed: {
        workshopId () {
          return this.mainMxCurrentWorkshopId;
        },
        startDate () {
          return this.$route.query.start;
        },
        endDate () {
          return this.$route.query.end;
        },
        workshopTopGroupsInPage () {
          if (!this.workshopTopGroups) return [];
          return this.workshopTopGroups.filter((entry, index) => {
            return index >= this.workshopTopGroupsStart && index < (this.workshopTopGroupsStart + 10);
          });
        }
      }
    }
</script>
