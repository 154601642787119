<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-file-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal">
        <span class="d-inline-flex">File Analytics <strong class="ms-2">{{ fileName }}</strong>
          <a v-if="infoPopoverAvailable(fileContentType)" href="#" v-sc-trackevent="'analytics:resource_info_popover'"
             class="hidden-print text-muted small my-auto" @click.prevent="infoPopoverOpen(fileId)">
            <ScIcon name="infoCircle" class="ms-2 small"/>
          </a>
          <small class="mx-2 text-muted" v-if="fileDeletedDate">(deleted: {{ fileDeletedDate }})</small>
        </span>
      </h2>

      <div ref="analyticsColumns" class="row"
           @vnode-mounted="analyticsColsMounted"
           @vnode-updated="analyticsColsUpdated"
           @vnode-unmounted="analyticsColsUnmounted">

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Summary</h5>
              <AnalyticsNoData :isLoading="fileReportSummaryLoading"/>

              <ul v-if="!fileReportSummaryLoading" class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(fileReportSummary.total_views) }}</strong>
                  </div>
                  File views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(fileReportSummary.total_shared) }}</strong>
                  </div>
                  Shared count
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(fileReportSummary.total_opened) }}</strong>
                  </div>
                  Opened count
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ ScDateUtil.formatVideoDurationSeconds(fileReportSummary.avg_viewed_time) }}</strong>
                  </div>
                  Avg viewed time
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top viewed users</h5>

              <div class="d-flex justify-content-end">
                <div class="text-muted">Total viewed time</div>
              </div>

              <AnalyticsNoData :isLoading="showcaseUserViewsLoading"/>
              <AnalyticsTopVideoUsers
                  v-if="!workshopTopVideoUsersLoading"
                  topVideoUsersCountCsvTitle="Top viewed users"
                  :topVideoUsers="workshopTopVideoUsers"
                  :ajaxParams="ajaxParams"
                  @paginated="paginated"></AnalyticsTopVideoUsers>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top users</h5>

              <div class="d-flex justify-content-end">
                <div class="text-muted">File views</div>
              </div>

              <AnalyticsNoData :isLoading="showcaseUserViewsLoading"/>
              <AnalyticsTopUsers v-if="!showcaseUserViewsLoading"
                                 :csvNameAddition="fileName"
                                 topUsersCountCsvTitle="File Views"
                                 :hasOpenedCount="false"
                                 :topUsersLinkShowcaseId="ajaxParams.showcaseId"
                                 :topUsers="showcaseUserViews"
                                 @paginated="paginated"></AnalyticsTopUsers>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Details and timelines</h5>

              <AnalyticsNoData :isLoading="fileEventListLoading" :noData="!fileEventList || fileEventList.length === 0"/>

              <ul v-if="!fileEventListLoading && fileEventList && fileEventList.length > 0" class="list-group list-group-flush">
                <li v-for="(item, idx) in fileEventList" :key="'evIt-'+ idx" class="list-group-item">
                  <AnalyticsFileEventItem
                      :item="item" :workspaceId="mainMxCurrentWorkshopId"
                      :headerImg="mainMxCurrentWorkshop.header_img"
                      :userCanPerformListFiles="mainMxUwCanPerform('a:list_files')"
                      @open-res-info-popover="mainMxShowResInfo($event)">
                    <template v-slot:sharedUserLink>
                      <router-link v-if="item.shared && item.shared.shared_user"
                                   :to="{ name: 'reporting-sharing-history',
                                          params: { workshopId: mainMxCurrentWorkshopId },
                                          query: { uid: item.shared.shared_user.id }}">
                        <template v-if="item.shared.shared_user.name">{{item.shared.shared_user.name}}</template>
                        <template v-else-if="item.shared.shared_user.email">{{item.shared.shared_user.email}}</template>
                      </router-link>
                    </template>
                  </AnalyticsFileEventItem>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top sharing users</h5>

              <div class="row">
                <div class="offset-8 col-2 text-center text-muted"
                     v-sc-tooltip="'Total count of items shared by this user'">Shared</div>
                <div class="col-2 text-center text-muted"
                     v-sc-tooltip="'Total count of file downloads & presentation views'">Viewed</div>
              </div>

              <AnalyticsNoData :isLoading="showcaseSharedSummaryLoading"/>
              <AnalyticsTopUsers v-if="!showcaseSharedSummaryLoading"
                                 :csvNameAddition="fileName"
                                 topUsersCountCsvTitle="Number files shared"
                                 :topUserLinkHref="true"
                                 :hasOpenedCount="true"
                                 :topUsers="showcaseTopSharedUsers"
                                 @paginated="paginated" ></AnalyticsTopUsers>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">File shares</h5>

              <AnalyticsNoData :isLoading="fileSharesLoading" :noData="fileSharesItems.length === 0"/>

              <ul v-if="!fileSharesLoading" class="list-group list-group-flush">
                <li v-for="item in fileSharesItems" :key="'ra-' + item.id"
                    class="list-group-item d-flex row">
                  <AnalyticsEventItem :item="item" :workspaceId="ajaxParams.workspace_id"
                                      :userCanPerformListFiles="mainMxUwCanPerform('a:list_files')"
                                      @open-res-info-popover="mainMxShowResInfo($event)">
                    <template v-slot:sharedUserLink>
                      <a v-if="item.shared && item.shared.shared_user" href="#"
                         @click.prevent="openSharingHistory(item.shared.shared_user.id)">
                        <template v-if="item.shared.shared_user.name">{{item.shared.shared_user.name}}</template>
                        <template v-else-if="item.shared.shared_user.email">{{item.shared.shared_user.email}}</template>
                      </a>
                    </template>
                  </AnalyticsEventItem>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
    import $ from 'jquery';
    import Masonry from 'masonry-layout';
    import _ from 'underscore'; // contains
    import {format} from 'date-fns';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import AnalyticsEventItem from '../../../shared/analytics/AnalyticsEventItem.vue';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsTopUsers from './AnalyticsTopUsers.vue';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import MainAppMixin from '../../MainAppMixin';
    import ScDateUtil from "../../global/ScDateUtil";
    import AnalyticsTopVideoUsers from "@/components/reporting/analytics/AnalyticsTopVideoUsers.vue";
    import AnalyticsFileEventItem from "@/shared/analytics/AnalyticsFileEventItem.vue";


    let _testNotEnoughData = false;

    let DataManager = function() {
      return {
        getFileSummary(ajaxParams, vm) {
          vm.fileReportSummary = {};
          vm.fileReportSummaryLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_file_report",
            data: ajaxParams

          }).done((data) => {
            //console.log(data, 'data');
            if (!_testNotEnoughData || data)
              vm.fileReportSummary = data;

          }).always(() => {
            vm.fileReportSummaryLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading file summary');
          });
        },

        getShowcaseUserViews(ajaxParams, vm) {
          if (vm.showcaseUserViewsLoading) return;
          vm.showcaseUserViewsLoading = true;
          vm.showcaseUserViews.splice(0);

          $.ajax({
            url: "/main/analytics/ajax_get_file_user_views",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.user_views) {
              vm.showcaseUserViews = data.user_views;
            }

          }).always(() => {
            vm.showcaseUserViewsLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading presentation file views');
          });
        },

        getShowcaseSharedSummary(ajaxParams, vm) {
          if (vm.showcaseSharedSummaryLoading) return;
          vm.showcaseSharedSummaryLoading = true;
          vm.showcaseTopSharedUsers.splice(0);

          $.ajax({
            url: "/main/analytics/ajax_get_file_shared_views",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.shared_views) {
              vm.showcaseTopSharedUsers = data.shared_views.users;
            }
          }).always(() => {
            setTimeout(() => {vm.showcaseSharedSummaryLoading = false; }, 1000);

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading presentation shared views');
          });
        },

        getFileShares(ajaxParams, vm) {
          if (vm.fileSharesLoading) return;
          vm.fileSharesLoading = true;
          vm.fileSharesItems.splice(0);

          $.ajax({
            url: "/main/analytics/ajax_get_file_shares",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data)
              vm.fileSharesItems = data.feed_items;

          }).always(() => {
            vm.fileSharesLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading file shares');
          })
        },

        getWorkshopTopVideoUsers(ajaxParams, vm) {
          if (vm.workshopTopVideoUsersLoading) return;
          vm.workshopTopVideoUsers.splice(0);
          vm.workshopTopVideoUsersLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_video_summary",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.users)
              vm.workshopTopVideoUsers = data.users;

          }).always(() => {
            vm.workshopTopVideoUsersLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top users for workshop');
          });
        },
        getFileEventList(ajaxParams, vm) {
          if (vm.fileEventListLoading) return;
          vm.fileEventList.splice(0);
          vm.fileEventListLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_analytics_items_for_file",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.feed_items || data.feed_items.length > 0)
              vm.fileEventList = data.feed_items;

          }).always(() => {
            vm.fileEventListLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading file analytics');
          });
        },
      };

    };

    let _dataManager = null;

    export default {
      name: "AnalyticsForFile",
      mixins: [ AnalyticsMixin, MainAppMixin ],
      components: {
        AnalyticsFileEventItem,
        AnalyticsTopVideoUsers, AnalyticsTopUsers, AnalyticsEventItem, AnalyticsNoData, ScIcon },
      props: {
        ajaxParams: {}
      },
      data () {
        return {
          fileReportSummary: {},
          fileReportSummaryLoading: false,
          showcaseUserViews: [],
          showcaseUserViewsLoading: false,
          workshopTopVideoUsers: [],
          workshopTopVideoUsersLoading: false,
          showcaseTopSharedUsers: [],
          showcaseSharedSummaryLoading: false,
          fileSharesItems: [],
          fileSharesLoading: false,
          fileEventListLoading: false,
          fileEventList: []
        };
      },
      mounted () {
        _dataManager = new DataManager();
        //console.log(this.ajaxParams, 'ajax');
        _dataManager.getFileSummary(this.ajaxParams, this);
        _dataManager.getShowcaseUserViews(this.ajaxParams, this);
        _dataManager.getShowcaseSharedSummary(this.ajaxParams, this);
        _dataManager.getFileShares(this.ajaxParams, this);
        _dataManager.getWorkshopTopVideoUsers(this.ajaxParams, this);
        _dataManager.getFileEventList(this.ajaxParams, this);
      },

      methods: {
        analyticsColsMounted () {
          this.$msnry = new Masonry( this.$refs.analyticsColumns, {
            itemSelector: '.analytics-grid-item',
            percentPosition: true
          });
        },
        analyticsColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        analyticsColsUnmounted () {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        },
        paginated () { // triggered masonry update after pagination
          this.$nextTick(() => {
            this.analyticsColsUpdated();
          });
        },

        infoPopoverAvailable (ct) {
          return this.mainMxUwCanPerform('a:list_files') &&
              _.contains(['image','document','movie'], ct);
        },
        infoPopoverOpen (resId) {
          this.mainMxShowResInfo(resId);
        },
        openSharingHistory (sharedId) {
          this.$router.push({name: 'reporting-sharing-history',
            params: {workshopId: this.ajaxParams.workspace_id}, query: { uid: sharedId }});
        }
      },
      computed: {
        ScDateUtil() {
          return ScDateUtil
        },
        fileName () {
          return (this.fileReportSummary.resource) ? this.fileReportSummary.resource.name : '';
        },
        fileContentType () {
          return (this.fileReportSummary.resource) ? this.fileReportSummary.resource.content_type : '';
        },
        fileId () {
          return (this.fileReportSummary.resource) ? this.fileReportSummary.resource.id : '';
        },
        fileDeletedDate () {
          return (this.fileReportSummary.deleted_date) ? format(new Date(this.fileReportSummary.deleted_date), 'dd MMM yyyy h:mm a') : '';
        }
      },
      watch: {
        ajaxParams () {
          _dataManager.getFileSummary(this.ajaxParams, this);
        }
      }
    }
</script>
