<template>
    <div>
        <div v-if="!rshIsOnline" class="row g-0 justify-content-center">
            <div class="col-12 text-center" style="margin-top: 30vh;">
                <p>You need to be online to use Sharing History.</p>
                <p><button @click.prevent="workspaceChanged()" class="btn btn-outline-secondary" type="button">Try again</button></p>
            </div>
        </div>

        <div v-else-if="rshIsOnline" class="row g-0">
            <div :class="['col-12 p-3 p-md-4 panel-sharing-recipients', showRecipientsPanel ? '' : 'd-none d-md-block']"
                 style="height: 90vh; overflow-y: scroll;">
                <div class="my-3">
                    <div>
                        <strong>Get sharing!</strong> Use the sharing feature, top right while viewing a presentation, or
                        use Share Content below to share content with others. Track the results here.
                    </div>
                    <button class="btn btn-outline-secondary my-3 fw-bold" @click.prevent="$emit('open-share-content')"
                        type="button"><ScIcon name="paperPlaneFW" class="me-1"/> Share Content</button>

                    <div class="input-group">
                        <input type="text" placeholder="Filter by name or email" class="form-control"
                               :value="searchValue ? searchValue.trim() : searchValue"
                               ref="searchInput" id="sharing-search-input" @keyup.enter="startSearch"
                               :disabled="spinnerRecipients || !hasSomeSharingEvents"/>

                        <button @click.prevent="startSearch" class="btn btn-outline-secondary input-group-text"
                                :disabled="spinnerRecipients || !hasSomeSharingEvents" type="button">
                            <ScIcon v-if="!spinnerRecipients" name="search"/>
                            <ScIcon v-else name="spinnerFW" class="text-muted"/>
                        </button>
                    </div>

                </div>

                <div v-if="!spinnerRecipients && !spinnerAnalyticsEvents && !hasSomeSharingEvents" class="mb-2">
                    If you have Sharing enabled on your presentation(s), the records will show up here.
                </div>

                <div v-if="searchValue && !spinnerRecipients && recipients.length > 0" class="text-muted mb-2">
                    Search results for "{{searchValue}}"
                    <button type="button" @click.prevent="clearSearch" class="btn btn-link"><ScIcon name="timesFW" class="text-muted"/></button>
                </div>

                <div v-if="searchValue && !spinnerRecipients && recipients.length === 0" class="mb-2">
                    <em class="text-muted">Oh dang. We can’t find any result for "{{searchValue}}"</em>
                    <button type="button" @click.prevent="clearSearch" class="btn btn-link"><ScIcon name="timesFW" class="text-muted"/></button>
                </div>

                <div class="pb-4">

                    <div v-if="!searchValue && !spinnerRecipients && hasSomeSharingEvents" @click="selectRecipient(null)"
                         :class="['d-block lead-item py-3', !selectedSharedUserId ? 'selected' : '']">
                        <strong><a href="#" @click.prevent="">All Sharing Events</a></strong>
                    </div>

                    <div v-for="item in recipients"
                         @click.prevent="selectRecipient(item.id)"
                         :class="['row lead-item py-3', selectedSharedUserId === item.id  ? 'selected' : '']"
                         :key="'lead-item2-' + item.id">

                        <div class="col">
                            <strong><a href="#" @click.prevent class="text-break">{{ recipientDisplayName(item) }}</a></strong>

                            <div v-if="item.last_analytics_event_type">
                                <span v-if="item.last_analytics_event_type === 'share_send'" class="text-muted">
                                Was sent sharing email</span>
                                <span v-if="item.last_analytics_event_type === 'share_link_generated'" class="text-muted">
                                Sharing link generated</span>
                                <span v-if="item.last_analytics_event_type === 'share_resource_download'" class="text-muted">
                                    <template v-if="item.shared_type === 'email'">Downloaded a file</template>
                                    <template v-else>File downloaded</template>
                                </span>
                                    <span v-if="item.last_analytics_event_type === 'share_page_view'" class="text-muted">
                                    <template v-if="item.shared_type === 'email'">Viewed sharing page</template>
                                    <template v-else>Sharing page viewed</template>
                                </span>
                                    <span v-if="item.last_analytics_event_type === 'showcase_view'" class="text-muted">
                                    <template v-if="item.shared_type === 'email'">Opened a Presentation</template>
                                    <template v-else>Presentation opened</template>
                                </span>
                        </div>
                        </div>
                        <small v-if="item.last_analytics_event_date" class="text-muted col-auto text-end ps-0" style="white-space: nowrap;">
                            {{ dateFmtDate(item.last_analytics_event_date) }}<br/>
                            {{ dateFmtHour(item.last_analytics_event_date) }}
                        </small>

                    </div>

                    <div v-if="moreRecipientsAvailable" class="text-center mt-3">
                        <button type="button" @click.prevent="loadMoreRecipients()" :disabled="spinnerRecipients"
                                class="btn btn-outline-secondary">
                            Load more <ScIcon v-if="spinnerRecipients" name="spinnerFW" class="ms-2" />
                        </button>
                    </div>
                </div>
            </div>
            <div :class="['col-12 p-3 p-md-4 panel-sharing-items border-left', showRecipientsPanel ? 'd-none d-md-block' : '']"
                 style=" height: 90vh; overflow-y: scroll;">

                <div v-if="!searchValue || selectedRecipient" class="mt-4">

                    <!-- csv export not enabled outside webapp -->

                    <div class="col-12 row p-0 m-0 mb-2">
                        <div class="col p-0 d-md-none">
                            <a href="#" @click.prevent="backToRecipients()" class="btn btn-link ps-0">
                                <strong><ScIcon name="angleLeftFW"/> Back</strong></a>
                        </div>
                    </div>

                    <div class="float-end" v-if="showCsvExport && analyticsEventItems.length > 0">
                        <button class="btn btn-link text-muted" @click.prevent="csvExport()" type="button"
                                v-sc-tooltip="'Export sharing events on screen as a CSV file'"><ScIcon name="download"/> CSV</button>
                    </div>

                    <h1 v-if="!selectedRecipient && hasSomeSharingEvents">All Sharing Events</h1>

                    <div v-if="selectedRecipient" class="col-12 col-sm-11 p-0 mx-sm-auto">
                        <template v-if="selectedRecipient.name">
                            <h1 class="d-inline-block text-break"> {{ selectedRecipient.name }}</h1>
                        </template>
                        <template v-else>
                            <h1 class="d-inline-block text-break">
                                <a href="#" @click.prevent="createMailto(selectedRecipient.email)">{{ selectedRecipient.email }}</a></h1>
                        </template>
                        <p>
                            <template v-if="selectedRecipient.name && selectedRecipient.email && selectedRecipient.email.indexOf('anonymous-recipient-') !== 0">
                                <button class="btn btn-link text-end p-0 text-truncate" type="button"
                                        @click.prevent="createMailto(selectedRecipient.email)">{{ selectedRecipient.email }}</button>
                                <br />
                            </template>
                            <small class="text-muted" v-if="selectedRecipient.last_analytics_event_date">
                                Last interaction {{ dateFmt(selectedRecipient.last_analytics_event_date) }}</small>
                            <button v-if="allowRenameRecipient" @click.prevent="renameSharedUser()"
                                    v-sc-tooltip="'Rename'" :disabled="renamingSharedUser"
                                    class="btn btn-link ms-3 px-2" type="button"><ScIcon name="pencil" class="text-muted"/></button>
                            <button v-if="allowDeleteRecipient" @click.prevent="delSharingItem(selectedRecipient)"
                                    v-sc-tooltip="'Delete interactions (Admins & Managers only)'" :disabled="deletingCurrentItem"
                                    class="btn btn-link px-2" type="button"><ScIcon name="trashAlt" class="text-muted"/></button>

                            <!-- rename/delete not enabled outside webapp -->
                        </p>
                    </div>

                    <ul class="list-group list-group-flush">
                        <li v-for="item in analyticsEventItems" :key="'ra-' + item.id"
                            class="list-group-item container" style="background-color: transparent">
                            <AnalyticsEventItem :item="item" :headerImg="headerImg"
                                                :userCanPerformListFiles="userCanPerformListFiles"
                                                :workspaceId="workspaceId"
                                                @open-res-info-popover="$emit('show-res-info-popover', $event)">
                                <template v-slot:sharedUserLink v-if="!selectedSharedUserId">
                                    <a v-if="item.shared && item.shared.shared_user" href="#"
                                       @click.prevent="selectRecipient(item.shared.shared_user.id)">
                                        <template v-if="item.shared.shared_user.name">{{item.shared.shared_user.name}}</template>
                                        <template v-else-if="item.shared.shared_user.email">{{item.shared.shared_user.email}}</template>
                                    </a>
                                </template>
                            </AnalyticsEventItem>
                        </li>
                    </ul>

                    <div v-if="spinnerAnalyticsEvents && !moreAnalyticsEventsAvailable" class="text-center mt-3">
                        <ScIcon name="spinnerFW" class="text-muted"/>
                    </div>
                    <div v-if="!spinnerAnalyticsEvents && analyticsEventItems.length === 0 && hasSomeSharingEvents" class="text-center mt-3">
                        <em class="text-muted">No interactions found</em>
                    </div>
                    <div v-if="moreAnalyticsEventsAvailable" class="text-center mt-3">
                        <a href="#" :disabled="spinnerAnalyticsEvents" @click.prevent="loadMoreAnalyticsEvents()"
                           class="btn btn-outline-secondary">Load more
                            <ScIcon v-if="spinnerAnalyticsEvents" name="spinnerFW" class="ms-1"/>
                        </a>
                    </div>
                    <div v-if="analyticsEventItems.length > 0" class="my-4">
                        <small class="text-muted">
                            <ScIcon name="questionCircle" class="ms-2 text-muted"/>
                            Showcase retains data for the past 3 years; data older than this is automatically deleted.
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import _ from 'underscore'; // has, findWhere
    import Papa from 'papaparse';
    import {format} from 'date-fns';
    import store from '../../store';
    import ReportingSharingHistoryStore from './ReportingSharingHistoryStore';
    import AnalyticsEventItem from './AnalyticsEventItem.vue';
    import ScCommonUtil from '../common/ScCommonUtil';
    import ScIcon from '../common/ScIcon.vue';
    import ScNativeExts from 'ScVueAliasScNativeExts';
    import ScConfirmModal2 from '../common/ScConfirmModal2.vue';
    import ScRenameModal2 from '../common/ScRenameModal2.vue';
    import ScCsvUtil from "../../components/global/ScCsvUtil";



    if (!store.state.rsh) store.registerModule('rsh', ReportingSharingHistoryStore);


    export default {
        name: 'SharingHistoryComp',
        emits: ['open-share-content', 'show-res-info-popover'],
        components: {AnalyticsEventItem, ScIcon},
        props: {
            workspaceId: {type: Number, default: null},
            headerImg: {type: String, default: null},
            allowDeleteRecipient: {type: Boolean, default: false},
            userCanPerformListFiles: {type: Boolean, default: false},
            showCsvExport: {type: Boolean, default: false}
        },
        data() {
            return {
                selectedSharedUserId: null,
                searchValue: null,
                spinnerRecipients: false,
                spinnerAnalyticsEvents: false,
                showRecipientsPanel: true,
                renamingSharedUser: false,
                deletingCurrentItem: false,
                hasSomeSharingEvents: false,
                onlineDetectInterval: null,
            }
        },
        mounted() {
            //console.log('sh hist comp mount');
            setTimeout(() => {  // allow our component to render before we kick off the store
              this.reset();
            })

            this.onlineDetectInterval = setInterval(() => {
                let isOnline = ScNativeExts.isOnline();
                if (isOnline !== this.rshIsOnline) {
                    this.$store.commit('rshOnline', isOnline);
                }
                //console.log('this.rshOnline', this.rshIsOnline);
            }, 5000);
        },

        beforeUnmount() {
            if (this.onlineDetectInterval) clearInterval(this.onlineDetectInterval);
        },
        methods: {
            clearAndLoadAnalyticsEvents() {
                this.$store.commit('rshResetAnalyticsEventItems');
                this.$store.commit('rshResetOffsetAnalyticsEventItems');
                this.loadMoreAnalyticsEvents();
            },
            startSearch() {
                this.searchValue = this.$refs.searchInput.value;
                this.selectedSharedUserId = null;
                this.$store.commit('rshResetAllRecipients');
                this.$store.commit('rshResetOffsetForRecipients');
                this.$store.commit('rshResetAnalyticsEventItems');
                this.$store.commit('rshResetOffsetAnalyticsEventItems');
                this.loadMoreRecipients();
                if (!this.searchValue) this.clearAndLoadAnalyticsEvents();
            },
            clearSearch() {
                this.searchValue = null;
                this.$store.commit('rshResetAllRecipients');
                this.$store.commit('rshResetOffsetForRecipients');
                this.selectedSharedUserId = null;
                this.loadMoreRecipients();
                this.clearAndLoadAnalyticsEvents();
            },
            recipientDisplayName(shared) {
                return (shared.name) ? shared.name : shared.email;
            },
            selectRecipient(sharedUserId) {
                if (this.spinnerRecipients || this.spinnerAnalyticsEvents) return;
                this.showRecipientsPanel = false;
                this.selectedSharedUserId = sharedUserId;
                this.clearAndLoadAnalyticsEvents();
            },
            loadMoreAnalyticsEvents() {
                if (this.spinnerAnalyticsEvents) return;
                this.spinnerAnalyticsEvents = true;
                this.$store.dispatch('rshLoadAnalyticsEventItems', {workspaceId: this.workspaceId, offset: this.offsetForAnalyticsEventItems, sharedUserId: this.selectedSharedUserId}).then(() => {
                    this.spinnerAnalyticsEvents = false;
                    if (this.analyticsEventItems.length > 0) this.hasSomeSharingEvents = true;
                });
            },
            loadMoreRecipients() {
                if (this.spinnerRecipients) return;
                this.spinnerRecipients = true;
                this.$store.dispatch('rshLoadSharingHistoryRecipients', {workspaceId: this.workspaceId, search: this.searchValue, offset: this.offsetForRecipients}).then(() => {
                    this.spinnerRecipients = false;
                    if (this.recipients.length > 0) this.hasSomeSharingEvents = true;
                });
            },
            backToRecipients() {
                this.showRecipientsPanel = true;
            },
            createMailto(url) {
                return ScNativeExts.openExternally('mailto:' + url, null, null);
            },

            delSharingItem(recipient) {
                ScConfirmModal2.modal('Upon deletion, the recipient loses access to active shared content. All ' +
                    'sharing history for this recipient is deleted from reporting. Users can still share to this ' +
                    'recipient in future. Are you sure?').then(() => {
                    this.deletingCurrentItem = true;
                    return this.$store.dispatch('rshDelSharedItem', {
                        workshopId: this.workspaceId, recipientId: recipient.id
                    }).then(() => {
                        this.deletingCurrentItem = false;
                        this.reset();
                    }, () => {
                        this.deletingCurrentItem = false;
                    });
                }, () => {});
            },
            renameSharedUser() {
                //console.log('renameSharedUser', recipient);
                ScRenameModal2.modal({name: this.selectedRecipient.name, maxLength: 128, allowEmpty: true}, (newName) => {
                    this.renamingSharedUser = true;
                    return this.$store.dispatch('rshRenameSharedUser', {
                        workshopId: this.workspaceId, recipientId: this.selectedRecipient.id, newName: newName
                    }).then(() => {
                        this.renamingSharedUser = false;
                        this.reset(this.selectedRecipient.id);
                    }, () => {
                        this.renamingSharedUser = false;
                    });
                });
            },
            workspaceChanged () {
                this.selectRecipient(null);
            },
            reset(selectedSharedUserId) {
                this.searchValue = null;
                this.selectedSharedUserId = (this.$route && this.$route.query.uid) ? Number(this.$route.query.uid) : null;
                if (selectedSharedUserId) this.selectedSharedUserId = selectedSharedUserId;
                this.$store.commit('rshResetAllRecipients');
                this.$store.commit('rshResetAnalyticsEventItems');
                this.$store.commit('rshResetOffsetForRecipients');
                this.$store.commit('rshResetOffsetAnalyticsEventItems');
                this.loadMoreAnalyticsEvents();
                this.loadMoreRecipients();
                if (this.selectedSharedUserId) this.selectRecipient(this.selectedSharedUserId)
            },
            dateFmt(dt) {
                return ScCommonUtil.scDateFmt(dt);
            },
            dateFmtDate(dt) {
                return this.dateFmt(dt).split(',')[0];
            },
            dateFmtHour(dt) {
                return this.dateFmt(dt).split(',')[1];
            },

            csvExport () {
                let csvData = [[ 'Event Date', 'Event Type', 'Lead Email', 'Lead Name', 'User Email', 'File Name' ]];
                for (let item of this.analyticsEventItems) {
                    let newLine = this.getLine(item);
                    csvData = csvData.concat(newLine);
                }
                let finalCsvText = Papa.unparse(csvData);
                let fileName = 'sharing-history-' + format(new Date(), 'yyyyMMddHHmm') + '.csv';
                ScCsvUtil.deliverCsv(finalCsvText, fileName);
            },
            getLine (item) {
                let csvLines = [];
                let types = {
                    'share_send': 'Send Email',
                    'share_link_generated': 'Sharing Link Generated',
                    'share_resource_download': 'Downloaded',
                    'share_page_view': 'Share Page Viewed',
                    'showcase_view': 'Viewed Presentation'
                };
                let csv = [
                    format(new Date(item.event_date), 'yyyy-MM-dd hh:mm:ss a'),
                    _.has(types, item.event_type) ? types[item.event_type] :item.event_type,
                    item.shared ? item.shared.email : '',
                    item.shared ? item.shared.name : '',
                    item.user ? item.user.email : ''
                ];
                if (item.event_type === 'share_send' || item.event_type === 'share_link_generated') {
                    // need one line in the csv for each shared resource
                    for (let res of  item.shared.resources) {
                        let csvCopy = csv.slice(0);
                        csvCopy.push(res.name);
                        csvLines.push(csvCopy);
                    }
                } else {
                    let fileName = '';
                    if (item.event_type === 'share_resource_download' && item.resource) fileName = item.resource.name;
                    if (item.event_type === 'showcase_view' && item.showcase) fileName = item.showcase.title;
                    csv.push(fileName);
                    csvLines.push(csv);
                }
                return csvLines;
            },
        },

        computed: {
            analyticsEventItems() {
                return this.$store.state.rsh.analyticsEventItems;
            },
            rshIsOnline() {
                return this.$store.state.rsh.rshIsOnline;
            },
            recipients() {
                return this.$store.state.rsh.allRecipients;
            },
            selectedRecipient() {
                return _.findWhere(this.recipients, {id: this.selectedSharedUserId});
            },
            moreRecipientsAvailable() {
                return this.$store.state.rsh.moreRecipientsAvailable;
            },
            offsetForRecipients() {
                return this.$store.state.rsh.offsetForRecipients;
            },
            moreAnalyticsEventsAvailable() {
                return this.$store.state.rsh.moreAnalyticsEventsAvailable;
            },
            offsetForAnalyticsEventItems() {
                return this.$store.state.rsh.offsetForAnalyticsEventItems;
            },
            allowRenameRecipient() {
                return !(this.selectedRecipient && this.selectedRecipient.name && this.selectedRecipient.name.indexOf('Anonymous Recipient ') === 0);
            }
        },
        watch: {
            workshopId() {
                this.reset();
            }
        }
    }
</script>

<style scoped>

    .lead-item {;
        border: 1px solid transparent;
    }

    .lead-item.selected {
        background-color: transparent;
    }

    .lead-item:hover {
        cursor: pointer;
    }
    #sharing-search-input::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }

    @media (min-width: 768px) {
        .panel-sharing-recipients {
            /* style of .col-5 */
            flex: 0 0 41.666667%;
            max-width: 41.666667%;
        }
        .panel-sharing-items {
            /* style of .col-7 */
            flex: 0 0 58.333333%;
            max-width: 58.333333%;
            padding: 0 1rem !important;
        }
        .lead-item {
            padding: 1rem;
        }
        .lead-item.selected {
            border-color: #c0c0c0;
            border-radius: 1rem;
            background-color: white;
        }

    }
    @media (max-width: 767px) {
        .panel-sharing-items {
            border-left: 1px solid transparent !important;
        }
    }

</style>