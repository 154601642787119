<template>
    <teleport to=".sc-modals-anchor">
        <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="$emit('modal-was-hidden')"
                class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="border: none;background-color: transparent;">
                    <div v-if="sharedType === 'email' && subject"
                            style="background-color: #f5f5f5;border: 1px solid #ccc;opacity: 0.8;" class="mb-1 p-1 rounded">
                        <strong class="d-inline-block me-3">Subject</strong>
                        <span v-if="subject">{{ subject }}</span>
                    </div>
                    <div v-if="(sharedType === 'vip-pass' || sharedType === 'link') && linkUrl"
                            style="background-color: #f5f5f5;border: 1px solid #ccc;opacity: 0.8;" class="mb-1 p-1 rounded">
                        <div class="text-center"><small class="text-break">{{linkUrl}}</small></div>
                    </div>

                    <SharingSummaryForRecipient
                            class="mx-auto shadow-lg"
                            :note="note" :resources="resources" :workspaceId="workspaceId"
                            :sharedId="sharedId" :sharerEmail="sharerEmail" :sharedType="sharedType"
                            :sharerDisplayName="sharerDisplayName"
                            :daysRemaining="daysRemaining"
                            :headerImg="headerImg"
                            :presentationId="presentationId"
                            :presentationTitle="presentationTitle"
                            :presentationThumbUrl="presentationThumbUrl"
                            :recordAnalytics="false"
                            :closeBtnShown="true" @close-btn-click="hideModal"
                            :previewOnly="true" :openAtOptions="openAtOptions"></SharingSummaryForRecipient>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>

    import SharingSummaryForRecipient from '../sharing/SharingSummaryForRecipient.vue'

    export default {
        name: "HistoricalSharingSummaryModal",
        components: {SharingSummaryForRecipient},
        emits: ['modal-was-hidden'],
        props: {
            sharedType: {type: String, default: null},
            subject: {type: String, default: null},
            linkUrl: {type: String, default: null},
            note: {type: String, default: null},
            resources: {type: Array, default: null},
            sharerEmail: {type: String, default: null},
            sharerDisplayName: {type: String, default: null},
            sharedId: {type: Number, default: null},
            workspaceId: {type: Number, default: null},
            daysRemaining: {type: Number, default: null},
            headerImg: {type: String, default: null},
            presentationId: {type: Number, default: null},
            presentationTitle: {type: String, default: null},
            presentationThumbUrl: {type: String, default: null},
            openAtOptions: {type: Object, default: null}
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
        }
    }
</script>
