<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-user-showcase-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal">
        User
        <strong v-if="user">{{ user.display_name }}</strong>
        <span v-if="user && user.user_workspace_ref"
              class="ms-2 text-muted h5 fw-normal">({{ user.user_workspace_ref }})</span>
        <span v-if="user && user.deleted_at"
              class="text-muted" v-sc-tooltip="'Removed at ' + mainMxScDateFmt(user.deleted_at)">(removed)</span>
        <br/>using <strong>{{ scTitle }}</strong>
      </h2>

      <div ref="analyticsColumns" class="row"
           @vnode-mounted="analyticsColsMounted"
           @vnode-updated="analyticsColsUpdated"
           @vnode-unmounted="analyticsColsUnmounted">

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Summary</h5>
              <AnalyticsNoData :isLoading="userShowcaseReportSummaryLoading"/>

              <ul v-if="!userShowcaseReportSummaryLoading" class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ (userShowcaseReportSummary.total_page_views) ? fmtInt(userShowcaseReportSummary.total_page_views ) : 0 }}</strong>
                  </div>
                  Slide views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ (userShowcaseReportSummary.total_resource_views) ?  fmtInt(userShowcaseReportSummary.total_resource_views) : 0 }}</strong>
                  </div>
                  File views
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top slides</h5>

              <AnalyticsNoData :isLoading="userShowcaseReportSummaryLoading" />
              <AnalyticsTopSlides v-if="userShowcaseReportSummary && !userShowcaseReportSummaryLoading"
                                  :csvNameAddition="csvNameAddition"
                                  :topSlides="userShowcaseReportSummary.page_views"
                                  :topSlidesTotalViews="userShowcaseReportSummary.total_page_views"
                                  @paginated="paginated"></AnalyticsTopSlides>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top files</h5>

              <div class="d-flex justify-content-end">
                <div class="text-muted">File views</div>
              </div>

              <AnalyticsNoData :isLoading="userShowcaseReportSummaryLoading" />
              <AnalyticsTopFiles v-if="!userShowcaseReportSummaryLoading"
                                 :csvNameAddition="csvNameAddition"
                                 topFilesCountCsvTitle="Views"
                                 :topFiles="userShowcaseReportSummary.resource_views"
                                 @paginated="paginated"></AnalyticsTopFiles>
            </div>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>

    import $ from 'jquery';
    import Masonry from 'masonry-layout';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsTopSlides from './AnalyticsTopSlides.vue';
    import AnalyticsTopFiles from './AnalyticsTopFiles.vue';
    import AnalyticsNoData from "./AnalyticsNoData.vue";
    import MainAppMixin from '../../MainAppMixin';

    let _testNotEnoughData = false;

    let _dataManager = null;

    let DataManager = function() {
      return {
        getUserShowcaseSummary(ajaxParams, vm) {
          if (vm.userShowcaseReportSummaryLoading) return;
          vm.userShowcaseReportSummary = {};
          vm.userShowcaseReportSummaryLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_user_showcase_report",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data)
              vm.userShowcaseReportSummary = data;

          }).always(() => {
            vm.userShowcaseReportSummaryLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading user presentation summary');
          });
        }
      };

    };

    export default {
      name: "AnalyticsForUserShowcase",
      mixins: [ AnalyticsMixin, MainAppMixin ],
      components: { AnalyticsTopSlides, AnalyticsTopFiles, AnalyticsNoData },
      props: {
        ajaxParams: {}
      },
      data () {
        return {
          userShowcaseReportSummary: {},
          userShowcaseReportSummaryLoading: false,
        };
      },
      mounted () {
        _dataManager = new DataManager();
        _dataManager.getUserShowcaseSummary(this.ajaxParams, this);

      },

      methods: {
        analyticsColsMounted () {
          this.$msnry = new Masonry( this.$refs.analyticsColumns, {
            itemSelector: '.analytics-grid-item',
            percentPosition: true
          });
        },
        analyticsColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        analyticsColsUnmounted () {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        },
        paginated () { // triggered masonry update after pagination
          this.$nextTick(() => {
            this.analyticsColsUpdated();
          });
        },
      },
      computed: {
        user () {
          return this.userShowcaseReportSummary.user;
        },
        scTitle () {
          return this.userShowcaseReportSummary.showcase_title;
        },
        csvNameAddition () {
          return (this.user && this.scTitle) ? this.user.display_name + ' ' + this.scTitle : '';
        }
      },
      watch: {
        ajaxParams () {
          _dataManager.getUserShowcaseSummary(this.ajaxParams, this);
        }
      },
    }
</script>
