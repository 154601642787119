<template>
  <div>
    <div v-if="totalDuration !== null" class="clearfix">
      <small class="float-end">
        Total <span class="duration">{{ durationToLongStr(totalDuration) }}</span>
      </small>
    </div>

    <div class="tl-container border rounded bg-white py-2">
      <div v-if="!isLoading && prepCols.length > 0">
        <template v-for="(col, idx) in prepCols" :key="idx">
          <a href="#" @click.prevent="openInfo(col)">
            <div class="py-1 float-start text-truncate" :style="timelineSliceStyleObj(col)"
                 v-sc-tooltip="'Click for details'">
              <small class="ps-2">
                <template v-if="col.isScreensaver">Screensaver</template>
                <template v-else>{{col.name}}</template>
              </small>
            </div>
          </a>
        </template>
      </div>

      <div v-if="isLoading" class="clearfix text-center">
        <ScIcon name="spinnerFW" class="m-2 text-muted" />
      </div>

      <div v-if="!isLoading && prepCols.length === 0" class="clearfix text-center mt-1">
        <em class="small text-muted">Not enough slide or file views to calculate timeline</em>
      </div>
    </div>

    <div class="container-fluid border rounded shadow mt-1 text-center p-3 bg-white w-100" v-if="showInfo">
      <div class="row justify-content-start mx-1">
        <div class="col-auto p-0">
          <button type="button" @click.prevent="closeInfo()" class="btn btn-link">
            <ScIcon name="timesFW" class="text-dark"/>
          </button>
        </div>
        <div class="col-auto p-0">
          <button type="button" :disabled="infoCol.idx <= 0" @click.prevent="prevInfo()" class="btn btn-link timeline-nav">
            <ScIcon name="chevronLeftFW" class="text-dark"/>
          </button>
          <button type="button" :disabled="infoCol.idx >= prepCols.length-1" @click.prevent="nextInfo()" class="btn btn-link timeline-nav">
            <ScIcon name="chevronRightFW" class="text-dark"/>
          </button>
        </div>
      </div>
      <div class="row align-items-center" style="min-height: 220px">
        <div class="col-12">
          <img v-if="infoCol.image" :src="infoCol.image" class="w-100"
               style="min-height: 100px; max-width: 200px" alt="Thumbnail"/>
          <div class="my-2 text-break">
            <strong>{{infoCol.name}}</strong> {{infoCol.title}}
          </div>

          <div v-if="infoCol.durationUnknown">
            Timeout
            <small class="text-muted">(device was left unattended or the app was closed)</small>
          </div>
          <div v-else >{{infoCol.displayDuration}}</div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

    import _ from 'underscore'; // contains, sortBy
    import $ from 'jquery';
    import {getTime, intervalToDuration} from 'date-fns';
    import ScNotification from '../common/ScNotification.vue';
    import ScIcon from '../common/ScIcon.vue';
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';

    const SLICE_COLOURS = ['#3D77B6', '#E24F59', '#F37F58', '#EABE5D', '#F39CA4',
        '#3E4965', '#775D4E', '#69835E', '#A3C76D', '#945A8A'];

    export default {
      name: "AnalyticsTimeline",
      components: {ScIcon},
      props: {
        workspaceId: {type: Number, default: null},
        item: {type: Object, default: null},
        shown: {type: Boolean, default: false}
      },
      data () {
        return {
          isLoading: false,
          showInfo: false,
          colData: [],
          infoCol: null,
          colors: SLICE_COLOURS
        };
      },
      mounted () {
        this.checkLoaded();
      },
      methods: {
        checkLoaded () {
          if (this.shown && !this.isLoading) {
            this.loadData(this);
          }
        },
        loadData () {
          this.isLoading = true;
          $.ajax({
            url: ViewerEmbedHandler.ajaxWrapUrl('/main/analytics/ajax_get_analytics_items_for_showcase_view'),
            data: {
              id: this.item.id, workspace_id: this.workspaceId,
              shared_id: this.item.shared ? this.item.shared.id : null,
              user_id: (this.item.user && !this.item.shared) ? this.item.user.id : null
            }, beforeSend: ViewerEmbedHandler.ajaxBeforeSend
          }).done((data) => {
            //console.log('loaded', data);
            this.colData = data;
            this.isLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'fetching timeline info');
            this.isLoading = false;
          });
        },
        durationToLongStr (tms) {
          let diffObj = intervalToDuration({ start: 0, end: tms });
          let hh = diffObj.hours;  //  hours as whole num eg, 3
          let mm = diffObj.minutes;  // mins past the hour as whole num eg, 1
          let ss = diffObj.seconds;  // mins past the hour as whole num eg, 1
          let displayDs = tms > 0 && tms < 2000;  // should we display deciseconds
          let durationArr = [
            tms > 0 && hh === 1 ? hh + ' hour ' : '',
            tms > 0 && hh > 1 ? hh + ' hours ' : '',

            tms > 0 && mm === 1 ? mm + ' minute ' : '',
            tms > 0 && mm > 1 ? mm + ' minutes ' : '',

            tms > 0 && (ss > 0 || displayDs) ? ss : '',

            tms > 0 && ss === 1 && (!displayDs) ? ' second ' : '',
            tms > 0 && ss > 1 || displayDs ? ' seconds ' : '',
            ''];
          return durationArr.join('');
        },
        timelineSliceStyleObj (col) {
          let boxShadow = '';
          if (col.borderPrev)
            boxShadow = '3px 0 0 -2px #fff inset';
          if (this.infoCol && this.infoCol.idx === col.idx && this.showInfo)
            boxShadow = '0 0 0 2px #000 inset';

          return {
            'width': (col.duration * 100 / this.totalDuration) + '%',
            'color': ((col.duration * 100 / this.totalDuration) > 6.5) ? 'white': 'transparent',
            'background-color': col.color,
            'overflow': 'hidden',
            'height': '32px',
            'box-shadow': boxShadow
          };
        },
        openInfo (col) {
          //console.log(col);
          if (this.infoCol && this.infoCol.id === col.id) {  // same col tapped again, toggle off
            this.showInfo = false;
            this.infoCol = null;
          }   else {
            this.showInfo = true;
            this.infoCol = col;
          }
        },
        nextInfo() {
          this.infoCol = this.prepCols[this.infoCol.idx + 1];
        },
        prevInfo () {
          this.infoCol = this.prepCols[this.infoCol.idx - 1];
        },
        closeInfo () {
          this.showInfo = false;
          this.infoCol = null;
        }
      },
      computed : {
        itemsToAdd () {
          if (!this.colData || !this.colData.analytics_items || this.colData.analytics_items.length === 0)
            return [];

          let itemsToAdd = [];
          for (let ai of this.colData.analytics_items) {
            // only show page and resource views
            if (_.contains(['page_view', 'resource_view', 'screensaver_view', 'search_view'], ai.event_type))
              itemsToAdd.push(ai);
          }
          return itemsToAdd;
        },

        prepCols () {
          if (this.itemsToAdd.length === 0)
            return [];

          let colObj = {};
          let tlCols = [];
          let itemsToAdd = this.itemsToAdd;
          let colorIdx = 0;

          for (let i = 0; i < itemsToAdd.length; i++) {
            let ai = itemsToAdd[i];
            //console.log(ai, 'ITEM');
            ai.duration_unknown = false;

            if (ai.event_duration_ms === 0 && i < (itemsToAdd.length - 1)) { // if duration not calculated yet, use the startTime of the next event if we have one
              ai.event_duration_ms = getTime(new Date(itemsToAdd[i + 1].event_date)) - getTime(new Date(ai.event_date));
            }
            if (ai.event_duration_ms > (20 * 60 * 1000) || ai.event_duration_ms === 0) {
              ai.event_duration_ms = 1000;  // yikes, we can't tell duration, set it to 1s
              ai.duration_unknown = true;
            }

            let itemName = 'Unknown';
            let itemImage = '';
            let itemTitle = '';
            if (ai.event_type === 'page_view') {
              itemName = ai.page && ai.page.alpha_num_name ? ai.page.alpha_num_name : 'Slide';
              itemImage = ai.page && ai.page.url_thumb ? ai.page.url_thumb : '';
              itemTitle = ai.page && ai.page.title ? ai.page.title : '';

            } else if (ai.event_type === 'resource_view') {
              itemName = 'File';
              if (ai.resource.content_type === 'movie') itemName = 'Video';
              if (ai.resource.content_type === 'weburl') itemName = 'URL';
              itemImage = ai.resource && ai.resource.thumb_url ? ai.resource.thumb_url : '';
              itemTitle = ai.resource && ai.resource.name ? ai.resource.name : '';
              if (itemTitle && itemTitle.length > 128) itemTitle = itemTitle.substring(0, 128) + '...';

            } else if (ai.event_type === 'screensaver_view') {
              itemName = 'Screensaver';
              itemImage = ai.resource && ai.resource.thumb_url ? ai.resource.thumb_url : '';
              itemTitle = ai.resource && ai.resource.name ? ai.resource.name : '';
            } else if (ai.event_type === 'search_view') {
              itemName = 'Search';
            }

            if (itemImage) (new Image()).src = itemImage; // pre-cache

            // pick same color for same slide/file
            let itemIdForColor = 'm' + Math.random();
            if (ai.page) itemIdForColor = 'p' + ai.page.id;
            if (ai.resource) itemIdForColor = 'r' + ai.resource.id;
            if (ai.event_type === 'search_view') itemIdForColor = 'search';
            if (!colObj[itemIdForColor]) {
              colObj[itemIdForColor] = this.colors[colorIdx];
              colorIdx++;
              if (colorIdx >= this.colors.length) colorIdx = 0;
            }

            let borderPrev = false;
            if (i > 0) {
              if (tlCols[i - 1].name === itemName) {
                borderPrev = true;
              }
            }

            tlCols.push({
              'id': ai.id,
              'idx': i,
              'name': itemName,
              'image': itemImage,
              'title': itemTitle,
              'color': colObj[itemIdForColor],
              'start': new Date(ai.event_start_ms),
              'end': new Date(ai.event_start_ms + ai.event_duration_ms),
              'durationUnknown': ai.duration_unknown,
              'duration': ai.event_duration_ms,
              'displayDuration': this.durationToLongStr(ai.event_duration_ms),
              'borderPrev': borderPrev,
              'isScreensaver': (ai.event_type === 'screensaver_view'),
              'isSearch': (ai.event_type === 'search_view')
            });
          }
          return _.sortBy(tlCols, function(o){ return o.start.getTime(); });

        },

        totalDuration () {
          if (!this.itemsToAdd || this.itemsToAdd.length === 0)
            return null;

          let timeTotalDurationMs = 0;
          for (let ai of this.itemsToAdd){
            timeTotalDurationMs += ai.event_duration_ms ? ai.event_duration_ms : 0;
          }
          return timeTotalDurationMs;

        }
      },
      watch: {
        shown () {
          this.checkLoaded();
        }
      }
    }
</script>

<style scoped>

    .tl-container {
        width: 100%;
        height: 50px;
        border: 1px solid rgba(0,0,0,.125);
        overflow: hidden;
    }

    .timeline-nav:disabled {
        opacity: 0.3;
    }


</style>