<template>
  <div v-if="isLoaded" class="analytics-top-showcases">
    <AnalyticsNoData :noData="topShowcases.length === 0"/>

    <ul v-if="topShowcases && topShowcases.length > 0"  class="list-group list-group-flush">
      <li v-for="(entry, index) in topShowcasesInPage" :key="'topSc' + index"
          class="list-group-item d-flex justify-content-between">
        <div>
          <router-link :to="{ name: 'reporting-analytics',
                              params: { workshopId: workshopId },
                              query: { scid: entry.id, start: startDate, end: endDate }}"
                       :title="'Analytics for ' + entry.name">
            <ScIcon name="chartBarFW" class="me-2"/>
            <span>{{ entry.name }}</span>
          </router-link>
        </div>
        <div class="ms-3 my-auto">{{ fmtInt(entry.count) }}</div>
      </li>
    </ul>
    <div v-if="topShowcases && topShowcases.length > 0" class="row mt-2">
      <div class="col-12">
        <div class="float-end" v-if="topShowcases.length > 10">
          <AnalyticsPaginator :total="topShowcases.length"
                              :currentStart="topShowcasesStart"
                              @update-start="updateTopShowcasesStart"></AnalyticsPaginator>
        </div>
        <button @click.prevent="exportTopShowcasesCsv()" class="btn btn-link text-muted"
                v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
      </div>
    </div>
  </div>
</template>

<script>
    import _ from 'underscore'; // isUndefined
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsPaginator from './AnalyticsPaginator.vue';
    import MainAppMixin from '../../MainAppMixin';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import AnalyticsNoData from './AnalyticsNoData.vue';

    export default {
      name: "AnalyticsTopShowcases",
      mixins: [ AnalyticsMixin, MainAppMixin ],
      components: { AnalyticsPaginator, ScIcon, AnalyticsNoData },
      props: {
        topShowcases: {type: Array, default: () => []},
        csvNameAddition: {type: String, default: null}
      },
      data () {
        return {
          topShowcasesStart: 0,
        }
      },
      methods: {
        updateTopShowcasesStart (v) {
          this.topShowcasesStart = v;
          this.$emit('paginated'); // trigger masonry update in parent
        },
        exportTopShowcasesCsv () {
          let csvLinesArr = [];
          for(let entry of this.topShowcases) {
            csvLinesArr.push([
              entry.name,
              this.fmtInt(entry.count)
            ]);
          }
          // remove all whitespaces and lowercase
          let csvName = 'top-presentations' + ((this.csvNameAddition) ? '-' + this.csvNameAddition.replace(/\s+/g, '-').toLowerCase() : '');
          this.exportCsv(csvName, ['Presentation name', 'Slide views'], csvLinesArr);
        }
      },
      computed: {
        isLoaded () {
          return !_.isUndefined(this.topShowcases);
        },
        workshopId () {
          return this.mainMxCurrentWorkshopId;
        },
        startDate () {
          return this.$route.query.start;
        },
        endDate () {
          return this.$route.query.end;
        },
        topShowcasesInPage() {
          if (!this.topShowcases) return [];
          return this.topShowcases.filter((entry, index) => {
            return index >= this.topShowcasesStart && index < (this.topShowcasesStart + 10);
          });
        }
      }
    }
</script>
