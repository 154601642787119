<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-file-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal">
        <span class="d-inline-block"> Slide Analytics<strong class="ms-2">{{ slideAlphaNumName }}</strong> {{ slideName }}

          <small class="mx-2 text-muted" v-if="slideDeletedDate">(deleted: {{ slideDeletedDate }})</small>
        </span>
      </h2>

      <div ref="analyticsColumns" class="row"
           @vnode-mounted="analyticsColsMounted"
           @vnode-updated="analyticsColsUpdated"
           @vnode-unmounted="analyticsColsUnmounted">

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Summary</h5>
              <AnalyticsNoData :isLoading="slideReportSummaryLoading"/>

              <ul v-if="!slideReportSummaryLoading" class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(slideReportSummary.total_views) }}</strong>
                  </div>
                  Total views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(slideReportSummary.tagged_count) }}</strong>
                  </div>
                  Current tagged count
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(slideReportSummary.fav_count) }}</strong>
                  </div>
                  Current favorited count
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top users</h5>

              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="showcaseUserViewsLoading"/>
              <AnalyticsTopUsers v-if="!showcaseUserViewsLoading"
                                 :csvNameAddition="`${slideAlphaNumName}-${slideName}`"
                                 topUsersCountCsvTitle="Slide Views"
                                 :hasOpenedCount="false"
                                 :topUsersLinkShowcaseId="ajaxParams.showcaseId"
                                 :topUsers="showcaseUserViews"
                                 @paginated="paginated"></AnalyticsTopUsers>
            </div>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
    import $ from 'jquery';
    import Masonry from 'masonry-layout';
    import {format} from 'date-fns';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsTopUsers from './AnalyticsTopUsers.vue';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import MainAppMixin from '../../MainAppMixin';


    let _testNotEnoughData = false;

    let DataManager = function() {
      return {
        getSlideSummary(ajaxParams, vm) {
          vm.slideReportSummary = {};
          vm.slideReportSummaryLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_slide_report",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data)
              vm.slideReportSummary = data;
            console.log(data)

          }).always(() => {
            vm.slideReportSummaryLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading slide summary');
          });
        },

        getShowcaseUserViews(ajaxParams, vm) {
          if (vm.showcaseUserViewsLoading) return;
          vm.showcaseUserViewsLoading = true;
          vm.showcaseUserViews.splice(0);

          $.ajax({
            url: "/main/analytics/ajax_get_slide_user_views",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.user_views) {
              vm.showcaseUserViews = data.user_views;
            }

          }).always(() => {
            vm.showcaseUserViewsLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading presentation slide views');
          });
        },

      };

    };

    let _dataManager = null;

    export default {
      name: "AnalyticsForSlide",
      mixins: [ AnalyticsMixin, MainAppMixin ],
      components: { AnalyticsNoData, AnalyticsTopUsers },
      props: {
        ajaxParams: {}
      },
      data () {
        return {
          slideReportSummary: {},
          slideReportSummaryLoading: false,
          showcaseUserViews: [],
          showcaseUserViewsLoading: false,
        };
      },
      mounted () {
        // console.log(this.ajaxParams, 'ajax');
        _dataManager = new DataManager();
        _dataManager.getSlideSummary(this.ajaxParams, this);
        _dataManager.getShowcaseUserViews(this.ajaxParams, this);
      },

      methods: {
        analyticsColsMounted () {
          this.$msnry = new Masonry( this.$refs.analyticsColumns, {
            itemSelector: '.analytics-grid-item',
            percentPosition: true
          });
        },
        analyticsColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        analyticsColsUnmounted () {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        },
        paginated () { // triggered masonry update after pagination
          this.$nextTick(() => {
            this.analyticsColsUpdated();
          });
        },

      },
      computed: {
        slideName () {
          return (this.slideReportSummary.page) ? this.slideReportSummary.page.title : '';
        },
        slideAlphaNumName () {
          return (this.slideReportSummary.page) ? this.slideReportSummary.page.alpha_num_name : '';
        },
        slideDeletedDate () {
          return (this.slideReportSummary.page && this.slideReportSummary.page.deleted_date) ? format(new Date(this.slideReportSummary.page.deleted_date), 'dd MMM yyyy h:mm a') : '';
        }
      },
      watch: {
        ajaxParams () {
          _dataManager.getSlideSummary(this.ajaxParams, this);
        }
      }
    }
</script>
