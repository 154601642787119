<template>
  <ScNormalPageLayout headingPart1="Form" headingPart2="Results" submenu="reporting">
    <div class="container mb-5 mt-3 mt-sm-5 sc-setting-email-container pb-4">
      <div class="row justify-content-center">
        <div class="col-11 col-sm-4 col-lg-3 pe-sm-0 pb-2">
          <form action="" @submit.prevent="">
            <div class="mb-3">
              <label>Date range
                <ScIcon name="questionCircle" class="text-muted"
                        v-sc-tooltip="'Max range between dates is 1 year. Showcase retains data for the past 3 years; data older than this is automatically deleted.'"/>
              </label>
              <div class="justify-content-between d-flex">
                <ScReportingRangeDatePicker @select-date-range="selectDateRange" :disabled="loadingItems"/>
              </div>
            </div>

            <div class="mb-3">
              <label>Search</label>
              <input type="text" class="form-control" v-model="formName" maxlength="128"
                     :disabled="loadingItems && !formNameFocused"
                     @focus="formNameFocused=true" @blur="formNameFocused=false"/>
            </div>

            <div class="mb-3 clearfix" v-if="prepUsers.length > 0 && mainMxUwCanPerform('a:list_any_form_results')">
              <label>Group / User</label>
              <ScSelectWithSearchAndGroups v-if="loadedUsers.length > 0 && loadedGroups.length > 0"
                                :disabled="loadingItems"
                                :items="[loadedGroups, loadedUsers]"
                                :labels="['Select a group', 'Select a user']"
                                placeholder="All groups and users"
                                reset="All groups and users"
                                @sc-select-item="selectUserOrGroupCallback" >
              </ScSelectWithSearchAndGroups>
            </div>

            <div class="mb-3 clearfix">
              <label>Presentation</label>
              <ScSelectWithThumbnailAndSearch v-if="loadedPresentations.length > 0"
                                :disabled="loadingItems"
                                :items="loadedPresentations"
                                placeholder="All presentations"
                                reset="All presentations"
                                @sc-select-item="selectPresentationCallback"
                                @deselect-item="selectPresentationCallback" >
              </ScSelectWithThumbnailAndSearch>
            </div>

            <div class="mb-3">
              <div class="customSelectArrow">
                <label>Sort by</label>
                <select class="form-control bg-white" v-model="selectedOrder"
                        @change="fetchFilteredData" :disabled="loadingItems" title="s-order">
                  <option selected value="desc">Newest first</option>
                  <option value="asc">Oldest first </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="col-11 col-sm-8 col-lg-9">
          <div class="results-container px-0 px-sm-3 py-3">
            <div v-if="!loadingItems" class="mb-4 row justify-content-between">
              <div class="col-12 col-sm-9 col-lg-10 order-sm-1 order-2">
                <p v-if="formItems.length > 0">
                  {{formItems.length}}
                  {{formItems.length === 1 ? 'record found' : 'records found'}}
                </p>
                <div v-if="formItems.length === 0" class="text-center pt-5">
                  <em class="text-muted">No records found</em>
                </div>
                <div v-if="formItems.length >= 1000" class="alert alert-warning">
                    More than 1,000 records found.  Only the first 1,000 are displayed, please filter.
                </div>
              </div>

              <div class="col-12 col-sm-3 col-lg-2 text-end order-sm-2 order-1">
                <button class="btn btn-link text-muted" @click.prevent="exportCsv" :disabled="formItems.length === 0"
                        v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
              </div>
            </div>

            <FormResultItem v-for="activityItem in formItems"
                            v-bind="activityItem" :key="activityItem.id"
                            @delete-result="deleteCallback"></FormResultItem>

            <div v-show="loadingItems">
              <div class="col-12 text-center pt-5">
                <ScIcon name="spinnerMedium" class="text-muted"/>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </ScNormalPageLayout>
</template>

<script>
    import $ from 'jquery';
    import Papa from 'papaparse';
    import _ from 'underscore'; // debounce, filter, indexOf, isArray, isObject
    import {format, subDays} from 'date-fns';
    import ScReportingRangeDatePicker from './ScReportingRangeDatePicker.vue';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScSelectWithSearchAndGroups from '../global/ScSelectWithSearchAndGroups.vue';
    import ScSelectWithThumbnailAndSearch from '../../shared/common/ScSelectWithThumbnailAndSearch.vue';
    import FormResultItem from './FormResultItem.vue';
    import StandardiseJSONMixin from './StandardiseJSONMixin';
    import MainAppMixin from '../MainAppMixin';
    import ScCsvUtil from "../global/ScCsvUtil";

    let _defaultPresentationId = 0;

    let DataManager = function() {
      return {
        fetchFilteredFormDataDebounce: _.debounce(function (vm) {
          _dataManager.fetchFilteredFormData(vm);
        },1000),

        fetchFilteredFormData (vm) {
          if (vm.loadingItems)
            return;
          vm.loadingItems = true;

          $.ajax({
            type: 'GET', url: "/main/activity/ajax_form_results",
            data: {
              workspace_id: vm.mainMxCurrentWorkshopId,
              form_name: vm.formName.trim(),
              presentation_id: vm.selectedPresentationId,
              user_id: vm.selectedUserId,
              group_id: vm.selectedGroupId,
              start_date: vm.startDate.toISOString(),
              end_date: vm.endDate.toISOString(),
              sort: vm.selectedOrder
            }
          }).done((data) => {
            //console.log(data);
            vm.formItems.splice(0);
            vm.formItems = data.form_data;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'fetching form items');

          }).always(() => {
            vm.loadingItems = false;

          });
        },
        fetchParamsData (vm) {
          $.ajax({
            type: 'GET', url: '/main/activity/ajax_activity_and_forms_params',
            data: {
              workspace_id: vm.mainMxCurrentWorkshopId
            }

          }).done((data) => {
            //console.log(data, 'fetchParamsDAta');
            if (data.types) {
              vm.loadedActivityTypes = data.types;
              vm.prepUsers(data.users);
              vm.prepGroups(data.groups);
              vm.prepPres(data.presentations);
            }
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'fetching options');
          });
        },

        deleteFormResult (guid, vm) {
          $.ajax({
            type: 'DELETE',
            url: '/api/v1/data/' + guid,
            data: {
              workspace_id: vm.mainMxCurrentWorkshopId
            }

          }).done(() => {
            ScNotification.growlSuccessMsg('Successfully deleted');
            vm.formItems = _.filter(vm.formItems, function(i){
              return (i.guid !== guid);
            });

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'deleting form result');
          });
        }
      };
    };

    let _dataManager = null;


    export default {
      name: "Form",
      mixins: [StandardiseJSONMixin, MainAppMixin],
      components: {ScNormalPageLayout, ScSelectWithSearchAndGroups, ScReportingRangeDatePicker, FormResultItem,
          ScIcon, ScSelectWithThumbnailAndSearch},
      data () {
        return {
          startDate: subDays(new Date(), 30),
          endDate: new Date(),

          selectedPresentationId: _defaultPresentationId,
          formName: '',
          formNameFocused: false,
          selectedUserId: 0,
          selectedGroupId: 0,
          selectedOrder: 'desc',

          loadingItems: false,
          loadedUsers: [],
          loadedGroups: [],
          loadedPresentations: [],
          loadedActivityTypes: [],
          formItems: [],
        };
      },
      mounted () {
        _dataManager = new DataManager();
        _dataManager.fetchParamsData(this);
        _dataManager.fetchFilteredFormData(this);
      },
      methods: {
        selectDateRange (dateArr) {
          //console.log('date range', dateArr)
          this.startDate = dateArr[0];
          this.endDate = dateArr[1];

          this.fetchFilteredData();
        },
        formatUserName (user) {
          return `${user.display_name} ${(user.display_name !== user.email ? ' <' + user.email + '>' : '')}  ${this.isDeleted(user)}`;
        },
        isDeleted (user) {
          let delDate = this.mainMxScDateFmt(user.deleted_at);
          return delDate ? ' (deleted ' + delDate + ')' : '';
        },
        prepUsers (data) {
          for (let u of data) {
            u.showName = this.formatUserName(u);
          }
          this.loadedUsers.splice(0);
          this.loadedUsers = data;
        },
        prepGroups (data) {
          for (let g of data) {
            g.showName = g.name;
          }
          this.loadedGroups.splice(0);
          this.loadedGroups = data;
        },
        prepPres (data) {
          for (let p of data) {
            p.showName = p.title;
          }
          this.loadedPresentations.splice(0);
          this.loadedPresentations = data;
        },
        selectUserOrGroupCallback (cb) {
          this.selectedUserId = 0;
          this.selectedGroupId = 0;

          if (cb && cb.display_name)
            this.selectedUserId = cb.id;
          else if (cb)
            this.selectedGroupId = cb.id;

          this.fetchFilteredData();
        },
        selectPresentationCallback (presId) {
          //console.log('cb', presId)
          if (presId === null)
            this.selectedPresentationId = 0;
          else
            this.selectedPresentationId = presId;
          this.fetchFilteredData();
        },
        deleteCallback (guid) {
          this.formItems =  _.filter(this.formItems, function(i){
            if (i.guid === guid)
              i.delete_pending = true;
            return true;
          });
          _dataManager.deleteFormResult(guid, this);
        },
        fetchFilteredData () {
          _dataManager.fetchFilteredFormData(this);
        },
        exportCsv () {
          //console.log('exportCsv', e);
          let headerLine = ['guid', 'Date', 'Presentation name', 'Form name', 'Submitted by'];
          let lines = [headerLine];
          for(let fi of this.formItems){
            let line = [
              fi.guid,
              format(new Date(fi.date_entered), 'yyyy-MM-dd hh:mm:ss a'),  // csv friendly datetime
              fi.presentation_title,
              fi.form_name,
              fi.user ? fi.user.email : (fi.shared ? (fi.shared.name ? fi.shared.name : fi.shared.email) : '')
            ];
            this.addFormattedInfodictForCsv(headerLine, line, fi.infodict);
            lines.push(line);
          }

          let finalCsvText = Papa.unparse(lines);
          let fileName = `form-results-${format(new Date(), 'yyyyMMddHHmm')}.csv`;
          ScCsvUtil.deliverCsv(finalCsvText, fileName);
        },
        addFormattedInfodictForCsv (headerLine, line, data) {
          function addValueToColNamed(colName, colValue) {
            //console.log('addValueToColNamed', colName, colValue);
            let colIdx = _.indexOf(headerLine, colName);
            if (colIdx === -1) {
              colIdx = headerLine.length;
              headerLine.push(colName);
            }
            while (line.length < headerLine.length) {
              line.push('');
            }
            line[colIdx] = colValue;
            //console.log('colIdx', colIdx, colValue);
          }
          if (data && data.length > 4 && (data.charAt(0) === '[' || data.charAt(0) === '{')) {
            let parsed;
            try {
              parsed = $.parseJSON(data);
            } catch (e) {
              parsed = {};
            }

            let standardisedJson = this.standardiseFormJson(parsed);
            let formatFields = function (baseName, fieldsArr) {
              if (!_.isArray(fieldsArr)) return null;
              for (let value of fieldsArr) {
                if (_.isObject(value.value))
                  formatFields(baseName + '' + value.name + '/', value.value);
                else
                  addValueToColNamed(baseName + '' + value.name, value.value);
              }
            };
            formatFields('', standardisedJson);

          } else {
            addValueToColNamed('Content', data);
          }
          //console.log('line fin', line);
        }
      },
      watch: {
        formName () {
          _dataManager.fetchFilteredFormDataDebounce(this);
        },

      }
    }

</script>

<style scoped>

  .results-container {
    min-height: 600px;
    border-left: 1px solid #e9e9f2;
    border-top: none;
  }
  @media (max-width: 576px) {
    .results-container {
      border-left: none;
      border-top: 1px solid #e9e9f2;
    }
  }

</style>