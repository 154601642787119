<template>
  <div v-if="canGoNext || canGoPrev">
    <button :disabled="!canGoPrev" @click.prevent="goPrev" type="button"
            title="Previous" class="mx-1 btn"><ScIcon name="chevronLeftFW" class="text-muted"/></button>
    <span v-if="endPageNum" class="text-muted">Page&nbsp;{{ startPageNum }}&nbsp;of&nbsp;{{ endPageNum }}&nbsp;&nbsp;</span>
    <button :disabled="!canGoNext" @click.prevent="goNext" type="button"
            title="Next" class="mx-1 btn"><ScIcon name="chevronRightFW" class="text-muted"/></button>
  </div>
</template>

<script>
    import ScIcon from '../../../shared/common/ScIcon.vue';

    export default {
      name: "AnalyticsPaginator",
      emits: ['update-start'],
      components: {ScIcon},
      props: {
        total: {type: Number, default: 0},
        currentStart: {type: Number, default: 0},
            amountPerPage: {type: Number, required: false, default: 10}
      },
      methods: {
        goNext(e) {
          if (this.canGoNext) this.updateStartPos(+this.amountPerPage, e);
        },
        goPrev(e) {
          if (this.canGoPrev) this.updateStartPos(-this.amountPerPage, e);
        },
        updateStartPos(v, e) {
          e.target.blur();
          this.$emit('update-start', this.currentStart + v);
        }
      },
      computed: {
        canGoNext() {
          return (this.currentStart+this.amountPerPage) < this.total;
        },
        canGoPrev() {
          return this.currentStart > 0;
        },
        startPageNum() {
          if (!this.total || !this.amountPerPage || this.total < this.amountPerPage) return null;
          return Math.floor(this.currentStart / this.amountPerPage) + 1;
        },
        endPageNum() {
          if (!this.total || !this.amountPerPage || this.total < this.amountPerPage) return null;
          return Math.ceil(this.total / this.amountPerPage);
        }
      }
    }
</script>
