<template>
  <div class="row py-3">

    <div class="col-auto my-auto">

      <div v-if="item.event_type === 'showcase_view' && item.showcase" class="aei-img-responsive-wrap">
        <img :src="item.showcase.url_thumb" v-sc-tooltip="item.showcase.title" alt="Thumbnail"
             class="aei-img-responsive d-block" />
      </div>

      <div v-else-if="item.resource" class="aei-img-responsive-wrap">
        <img :src="item.resource.thumb_url" v-sc-tooltip="item.resource.name" alt="Thumbnail"
             class="aei-img-responsive d-block" />
      </div>
      <ScIcon v-else-if="1===1" :name="analyticsEventTypeIcon"
              class="aei-icon-responsive d-block text-center text-muted" />

    </div>
    <div class="col p-0">
      <!-- who performed the action -->
      <div v-if="showShareTitle" class="text-break">
        <strong>
          {{shareTitleExtraContext}}
          <slot name="sharedUserLink">
            <span v-if="item.shared.shared_user.name">"{{item.shared.shared_user.name}}"</span>
            <span v-else-if="item.shared.shared_user.email">{{item.shared.shared_user.email}}</span>
          </slot>
        </strong>
      </div>
      <div v-else-if="showUserTitle" class="text-break">
        <strong>User "{{ item.user.display_name }}"</strong>
      </div>

      <!-- what did they do -->
      <div class="text-break">
        <span v-if="item.event_type === 'share_send' || item.event_type === 'share_link_generated'" class="py-1">
          <span v-if="item.shared && item.shared.shared_type === 'email'">
            Sent sharing email to </span>
          <span v-else-if="item.shared && item.shared.shared_type !== 'email'">
            Created {{shareTitleExtraContext}}&nbsp;</span>
          <slot name="sharedUserLink">
            <span v-if="item.shared.shared_user.name">"{{item.shared.shared_user.name}}"</span>
            <span v-else-if="item.shared.shared_user.email">{{item.shared.shared_user.email}}</span>
          </slot>
          from presentation "{{item.showcase.title}}".
          <small class="text-muted" v-if="isExpired">(shared content expired {{ expiredDate }})</small>
        </span>

        <span v-if="item.event_type === 'share_resource_download'" class="py-1">
          <span v-if="item.shared && item.shared.shared_type === 'email'">
            Downloaded</span>
          <span v-if="item.resource && item.resource.name">
            "{{ item.resource.name }}"</span>
          <span v-if="item.shared && item.shared.shared_type === 'vip-pass'">
            was downloaded</span>
          <span v-if="item.shared && item.shared.shared_type === 'link'">
            was downloaded</span>
          <a v-if="infoPopoverAvailable(item.resource.content_type)" href="#" v-sc-trackevent="'sharing_history:resource_info_popover'"
             class="hidden-print"
             @click.prevent="infoPopoverOpen(item.resource.id)"><ScIcon name="infoCircle" class="ms-2"/></a>
        </span>
        <span v-if="item.event_type === 'share_page_view'" class="py-1">
          <span v-if="item.shared && item.shared.shared_type === 'email'">
            Viewed sharing page</span>
          <span v-else>
            Sharing page viewed</span>
        </span>
        <span v-if="item.event_type === 'showcase_view'" class="py-1">
          <span v-if="!item.shared || (item.shared && item.shared.shared_type === 'email')">
            Opened presentation</span>
          <span v-if="item.shared && item.shared.shared_type !== 'email'">
            Presentation</span>
          <span v-if="item.showcase && item.showcase.title">
            "{{ item.showcase.title }}"</span>
          <span v-if="item.shared && item.shared.shared_type === 'vip-pass'">
            was opened</span>
          <span v-if="item.shared && item.shared.shared_type === 'link'">
            was opened</span>
        </span>

        <span v-if="showSharedInfoLink" class="mx-1">
          <a @click.prevent="historicalSharingSummaryModalSharedId=item.shared.id" href="#"
             class="hidden-print"><ScIcon name="infoCircle" /></a>
          <HistoricalSharingSummaryModal v-if="item.shared.id === historicalSharingSummaryModalSharedId"
                                         :key="'hssm-' + historicalSharingSummaryModalSharedId"
                                         :sharedId="item.shared.id" :workspaceId="workspaceId"
                                         :subject="item.shared.subject" :note="item.shared.note" :resources="item.shared.resources"
                                         :sharerEmail="item.user.email" :sharerDisplayName="item.user.display_name"
                                         :headerImg="headerImg" :presentationTitle="item.showcase.title"
                                         :presentationThumbUrl="item.showcase.url_thumb" :linkUrl="item.shared.link_url"
                                         :sharedType="item.shared.shared_type" :daysRemaining="item.shared.days_remaining"
                                         :openAtOptions="sharedOpenAtOptions"
                                         @modal-was-hidden="historicalSharingSummaryModalSharedId=null"></HistoricalSharingSummaryModal>
        </span>
      </div>

      <div v-if="item.shared && item.shared.sent_email_bounced" class="alert alert-warning mt-2">
        Email bounced with reason: {{ item.shared.sent_email_bounce_desc }}.</div>

      <div v-if="showTimelineLink">
        <a href="#" @click.prevent="showTimelineChart = !showTimelineChart">
          Timeline
          <ScIcon v-if="!showTimelineChart" name="angleDown" class="ms-2"/>
          <ScIcon v-else name="angleUp" class="ms-2"/></a>
      </div>
    </div>
    <div class="col-3 text-end ps-0 py-1">
      <div class="ms-2 mb-2">
        <small class="text-muted">
          {{ dateFmtDate(item.event_date) }} <br/>
          {{ dateFmtHour(item.event_date) }}
          <span v-if="item.client_marketing_name"
                v-sc-tooltip="
                               item.client_marketing_name + '; ' +
                               (item.device_desc ? item.device_desc + '; ':'') +
                               (item.device_viewport ? item.device_viewport + 'px; ' : '') +
                               (item.user_language ? item.user_language + '; ' : '') ">
            <ScIcon :name="parsedClientIcon(item)"/>
          </span>
        </small>
      </div>
    </div>
    <AnalyticsTimeline v-if="showTimelineChart" :class="['col-md-12 timeLineContainer border-0' + item.id]"
                       :workspaceId="workspaceId"
                       :item="item"
                       :shown="showTimelineChart" ></AnalyticsTimeline>
  </div>
</template>

<script>

    import _ from 'underscore'; // contains
    import AnalyticsTimeline from './AnalyticsTimeline.vue';
    import HistoricalSharingSummaryModal from './HistoricalSharingSummaryModal.vue';
    import ScCommonUtil from '../common/ScCommonUtil';
    import ScIcon from '../common/ScIcon.vue';
    import {addDays, format} from 'date-fns';

    let _analyticsIconNames = {
      'default': 'circleFWSmall',
      'showcase_view': 'circleFWSmall',
      'share_send': 'shareSquareFWSmall',
      'share_link_generated': 'shareSquareFWSmall',
      'share_page_view': 'fileAltFWSmall',
      'share_resource_download': 'cloudDownloadFWSmall'
    };

    export default {
      name: "AnalyticsEventItem",
      emits: ['open-res-info-popover'],
      components: {HistoricalSharingSummaryModal, AnalyticsTimeline, ScIcon},
      props: {
        item: Object,
        headerImg: {type: String, default: null},
        workspaceId: {type: Number, default: null},
        userCanPerformListFiles: {type: Boolean, default: false}
      },
      data () {
        return {
          showTimelineChart: false,
          historicalSharingSummaryModalSharedId: null
        };
      },
      mounted () {
        //
      },
      methods: {
        infoPopoverAvailable (ct) {
          return this.userCanPerformListFiles &&
              _.contains(['image','document','movie'], ct);
        },
        infoPopoverOpen (resId) {
          this.$emit('open-res-info-popover', resId);
        },
        parsedClientIcon(item) {
          return ScCommonUtil.parseIconFromClientName(item.client_name);
        },
        dateFmt(dateTimeStr) {
          return ScCommonUtil.scDateFmt(dateTimeStr);
        },
        dateFmtDate(dt) {
          return this.dateFmt(dt).split(',')[0];
        },
        dateFmtHour(dt) {
          return this.dateFmt(dt).split(',')[1];
        },
      },
      computed: {
        shareTitleExtraContext() {
          if (!this.item.shared || !this.item.shared.shared_user) return '';
          if (this.item.shared.shared_type === 'email') return 'Recipient';
          if (this.item.shared.shared_type === 'vip-pass' && this.item.shared.shared_user.name &&
              !this.item.shared.shared_user.name.match(/VIP Pass [0-9]+/)) return 'VIP Pass';
          if (this.item.shared.shared_type === 'link' && this.item.shared.shared_user.name &&
              !this.item.shared.shared_user.name.match(/Link [0-9]+/)) return 'Link';
          return '';
        },
        sharedOpenAtOptions() {
          try {
            return this.item.shared.open_at_options ? JSON.parse(this.item.shared.open_at_options) : null;
          } catch {
            return null;
          }
        },
        analyticsEventTypeIcon() {
          return (!this.item.event_type || !_analyticsIconNames[this.item.event_type]) ? 'circleFWSmall' : _analyticsIconNames[this.item.event_type];
        },
        showShareTitle: function() {
          return this.item.shared &&
              _.contains(['share_resource_download', 'share_page_view', 'showcase_view'],
                  this.item.event_type);
        },
        showUserTitle() {
          return this.item.user &&
              _.contains(['showcase_view', 'share_send', 'share_link_generated'], this.item.event_type);
        },
        showSharedInfoLink() {
          return this.item.shared &&
              _.contains(['share_send', 'share_link_generated', 'share_page_view'], this.item.event_type);
        },
        showTimelineLink() {
          return this.item.event_type === 'showcase_view' && this.item.showcase && this.item.showcase.title;
        },
        isExpired() {
          return (this.item.shared) ? this.item.shared.days_remaining < 0 : false;
        },
        expiredDate() {
          if (!this.item.shared || this.item.shared.days_remaining === null) return '';
          return format(addDays(new Date(), this.item.shared.days_remaining), 'd MMM yyyy');
        }
      }
    }
</script>

<style scoped>

  .aei-img-responsive-wrap {
    width: 60px;
  }
  .aei-img-responsive {
    max-width: 60px;
    max-height: 60px;
    height: auto;
  }
  .aei-icon-responsive {
    min-width: 60px;
  }

  @media (max-width: 575.98px) {
    .aei-img-responsive-wrap {
      width: 40px;
    }
    .aei-img-responsive {
      max-width: 40px;
      max-height: 40px;
    }
    .aei-icon-responsive {
      min-width: 40px;
    }
  }

</style>