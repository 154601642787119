<template>
  <div class="analytics-top-devices">
    <AnalyticsNoData :noData="workshopTopDevices.length === 0"/>

    <ul v-if="workshopTopDevices.length > 0" class="list-group list-group-flush">
      <li v-for="(entry, index) in workshopTopDevicesForPage" :key="'wtd-' + index"
          class="list-group-item d-flex justify-content-between">
        <div>
          <ScIcon :name="parseClientIcon(entry)" class="me-2"/>
          <span>{{ entry.device }}</span>
          <br/><small class="text-muted fw-normal">{{ entry.client_marketing_name }}</small>
        </div>
        <div class="ms-3 my-auto">{{ fmtInt(entry.count) }}</div>
      </li>
    </ul>
    <div v-if="workshopTopDevices.length > 0" class="row mt-2">
      <div class="col-12">
        <div class="float-end" v-if="workshopTopDevices.length > 10">
          <AnalyticsPaginator :total="workshopTopDevices.length"
                              :currentStart="workshopTopDevicesStart"
                              @update-start="updateWorkshopTopDevicesStart"></AnalyticsPaginator>
        </div>
        <button @click.prevent="exportTopDevicesCsv()" class="btn btn-link text-muted"
                v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
      </div>
    </div>
  </div>
</template>

<script>
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsPaginator from './AnalyticsPaginator.vue';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';

    export default {
      name: "AnalyticsTopDevices",
      mixins: [ AnalyticsMixin ],
      components: { AnalyticsPaginator, ScIcon, AnalyticsNoData },
      props: {
        workshopTopDevices: {type: Array, default: () => []}
      },
      data () {
        return {
          workshopTopDevicesStart: 0
        }
      },
      methods: {
        updateWorkshopTopDevicesStart (v) {
          this.workshopTopDevicesStart = v;
          this.$emit('paginated')
        },
        exportTopDevicesCsv () {
          let csvLinesArr = [];
          for (let entry of this.workshopTopDevices) {
            csvLinesArr.push([
              entry.device,
              entry.client_marketing_name,
              this.fmtInt(entry.count)
            ]);
          }
          this.exportCsv('top-devices', ['Device', 'Client', 'Slide Views'], csvLinesArr);
        },
      },
      computed: {
        workshopTopDevicesForPage () {
          if (!this.workshopTopDevices) return [];
          return this.workshopTopDevices.filter((entry, index) => {
            return index >= this.workshopTopDevicesStart && index < (this.workshopTopDevicesStart + 10);
          });
        }
      }
    }
</script>
