<template>
    <div>
        <canvas ref="chart1" :style="{height: this.height + 'px'}"></canvas>
    </div>
</template>

<script>

    import {Chart, LineController, LineElement, PointElement, LinearScale, Tooltip, CategoryScale} from 'chart.js';
    import {format} from 'date-fns';

    Chart.register(LineController, LineElement, PointElement, LinearScale, Tooltip, CategoryScale);
    Chart.defaults.font.size = 14;

    export default {
        props: {
            data: { type: Object, default: null },
            multiple: { type: Boolean, default: false },
            height: {type: Number, default: 200}
        },
        mounted () {
            let ctx = this.$refs.chart1.getContext('2d');
            //console.log('ctx', ctx);
            let vm = this;
            new Chart(ctx, {
                type: 'line',
                data: this.data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            ticks: {
                                 callback: function(value) {
                                     //console.log('tick cb', value, vm.data.labels[value]);
                                     let rawDate = vm.data.labels[value];
                                     return format(new Date(rawDate), 'd MMM');
                                 }
                            },
                        },
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0,
                            },
                            //gridLines: { drawBorder: true }
                        }
                    },

                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: () => null,
                                label: function(context) {
                                    //console.log('label', context, context.dataset.scChartTooltipPrefix);
                                    return ' ' + format(new Date(context.label), 'MMM d, yyyy') + ' - ' +
                                         context.dataset.scChartTooltipPrefix + ': ' + context.raw;
                                }
                            },
                        }
                    }
                }
            });
        }
    }

</script>