<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-showcase-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal">Presentation Analytics <strong>{{ scTitle }}</strong>
        <span v-if="showcase_deleted"> (deleted)</span></h2>

        <div ref="analyticsColumns" class="row"
             @vnode-mounted="analyticsColsMounted"
             @vnode-updated="analyticsColsUpdated"
             @vnode-unmounted="analyticsColsUnmounted">

          <div class="analytics-grid-item col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-center">Summary</h5>

                <AnalyticsNoData :isLoading="showcaseReportSummaryLoading || showcaseResourceViewsLoading || showcaseSharedSummaryLoading"/>

                <ul v-if="!showcaseReportSummaryLoading && !showcaseResourceViewsLoading && !showcaseSharedSummaryLoading"
                    class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="float-end">
                      <strong class="h4">{{ fmtInt(showcaseSummaryTotalPageViews )}}</strong>
                    </div>
                    Slide views
                  </li>
                  <li class="list-group-item">
                    <div class="float-end">
                      <strong class="h4">{{ fmtInt(showcaseResourceTotalViews) }}</strong>
                    </div>
                    File views
                  </li>
                  <li class="list-group-item">
                    <div class="float-end">
                      <strong class="h4">{{ fmtInt(showcaseSharedTotalItems) }}</strong>
                    </div>
                    Shared items
                  </li>
                </ul>

              </div>
            </div>
          </div>

          <div class="analytics-grid-item col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-center">Top files</h5>

                <div class="d-flex justify-content-end">
                  <div class="text-muted">File views</div>
                </div>

                <AnalyticsNoData :isLoading="showcaseResourceViewsLoading"/>
                <AnalyticsTopFiles v-if="!showcaseResourceViewsLoading"
                                   :csvNameAddition="scTitle"
                                   topFilesCountCsvTitle="Views"
                                   :topFiles="showcaseResourceViews" @paginated="paginated"></AnalyticsTopFiles>
              </div>
            </div>
          </div>


          <div class="analytics-grid-item col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-center">Top slides</h5>

                <AnalyticsNoData :isLoading="showcasePageViewsLoading"/>
                <AnalyticsTopSlides v-if="!showcasePageViewsLoading && showcasePageViews"
                                    :csvNameAddition="scTitle"
                                    :linkDisabled="showcase_deleted"
                                    :topSlides="showcasePageViews"
                                    :topSlidesTotalViews="showcasePageTotalViews"
                                    @paginated="paginated"></AnalyticsTopSlides>
              </div>
            </div>
          </div>

          <div class="analytics-grid-item col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-center">Top users</h5>

                <div class="d-flex justify-content-end">
                  <div class="text-muted">Slide views</div>
                </div>

                <AnalyticsNoData :isLoading="showcaseUserViewsLoading"/>
                <AnalyticsTopUsers v-if="!showcaseUserViewsLoading"
                                   :csvNameAddition="scTitle"
                                   topUsersCountCsvTitle="Slide Views"
                                   :hasOpenedCount="false"
                                   :topUsersLinkShowcaseId="ajaxParams.showcaseId"
                                   :topUsers="showcaseUserViews"
                                   @paginated="paginated"></AnalyticsTopUsers>
              </div>
            </div>
          </div>

          <div class="analytics-grid-item col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-center">Top sharing users</h5>

                <div class="row">
                  <div class="offset-8 col-2 text-center text-muted"
                       v-sc-tooltip="'Total count of items shared by this user'">Shared</div>
                  <div class="col-2 text-center text-muted"
                       v-sc-tooltip="'Total count of file downloads & presentation views'">Viewed</div>
                </div>

                <AnalyticsNoData :isLoading="showcaseSharedSummaryLoading"/>
                <AnalyticsTopUsers v-if="!showcaseSharedSummaryLoading"
                                   :csvNameAddition="scTitle"
                                   topUsersCountCsvTitle="Number files shared"
                                   :topUserLinkHref="true"
                                   :hasOpenedCount="true"
                                   :topUsers="showcaseTopSharedUsers"
                                   @paginated="paginated"></AnalyticsTopUsers>
              </div>
            </div>
          </div>

          <div class="analytics-grid-item col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-center">Top shared items</h5>

                <div class="row">
                  <div class="offset-8 col-2 text-center text-muted"
                       v-sc-tooltip="'Total count of shares for this item by all users'">Shared</div>
                  <div class="col-2 text-center text-muted"
                       v-sc-tooltip="'Total count of file downloads or presentation views for this item'">Viewed</div>
                </div>

                <AnalyticsNoData :isLoading="showcaseSharedResourcesLoading"/>
                <AnalyticsTopFiles v-if="!showcaseSharedResourcesLoading && showcaseSharedResources"
                                   :csvNameAddition="scTitle"
                                   topFilesCountCsvTitle="Number times shared"
                                   :hasOpenedCount="true"
                                   :topFiles="showcaseSharedResources"
                                   @paginated="paginated"></AnalyticsTopFiles>
              </div>
            </div>
          </div>

          <div class="analytics-grid-item col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-center">Sharing summary</h5>

                <AnalyticsNoData :isLoading="showcaseSharedSummaryLoading"/>

                <ul v-if="!showcaseSharedSummaryLoading" class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="float-end">
                      <strong class="h4">{{ fmtInt(showcaseSharedTotalItems) }}</strong>
                    </div>
                    Shared items
                  </li>
                  <li class="list-group-item">
                    <div class="float-end">
                      <strong class="h4">{{ fmtInt(showcaseSharedTotalViews) }}</strong>
                    </div>
                    Viewed items
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="analytics-grid-item col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-center">Shared items by day</h5>

                <AnalyticsNoData :isLoading="showcaseSharedSummaryLoading" :noData="!showcaseSharedByDayHasValues"/>

                <line-chart v-if="!showcaseSharedSummaryLoading && showcaseSharedByDayHasValues"
                            :data="sharedByDayChartData" :height="200"  :multiple="true"></line-chart>
              </div>
            </div>
          </div>

        </div>
    </div>

  </transition>
</template>

<script>
    import $ from 'jquery';
    import Masonry from 'masonry-layout';
    import LineChart from './LineChart.vue';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsTopUsers from './AnalyticsTopUsers.vue';
    import AnalyticsTopSlides from './AnalyticsTopSlides.vue';
    import AnalyticsTopFiles from './AnalyticsTopFiles.vue';
    import AnalyticsNoData from './AnalyticsNoData.vue';


    let _testNotEnoughData = false;

    let _dataManager = null;

    let DataManager = function () {
      return {

        getShowcaseSummary(ajaxParams, vm) {
          if (vm.showcaseReportSummaryLoading) return;
          vm.showcaseReportSummary = {};
          vm.showcaseReportSummaryLoading = true;
          vm.showcaseSummaryTotalPageViews = null;

          $.ajax({
            url: "/main/analytics/ajax_get_showcase_summary",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data) {
              vm.showcaseReportSummary = data;
              if (data && data.total_views) {
                vm.showcaseSummaryTotalPageViews = 0;
                for (let item of data.total_views) {
                  vm.showcaseSummaryTotalPageViews += item.count ? item.count : 0;
                }
              }
            }

          }).always(() => {
            setTimeout(() => {vm.showcaseReportSummaryLoading = false; }, 1000);

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading presentation summary');
          });
        },

        getShowcasePageViews(ajaxParams, vm) {
          if (vm.showcasePageViewsLoading) return;
          vm.showcasePageViewsLoading = true;
          vm.showcasePageTotalViews = null;
          vm.showcasePageViews = [];

          $.ajax({
            url: "/main/analytics/ajax_get_showcase_page_views",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.page_views) {
              vm.showcasePageTotalViews = 0;
              vm.showcasePageViews = data.page_views;
              for (let item of data.page_views) {
                vm.showcasePageTotalViews += item.count ? item.count : 0;
              }
            }

          }).always(() => {
            vm.showcasePageViewsLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading presentation slide views');
          });
        },

        getShowcaseUserViews(ajaxParams, vm) {
          if (vm.showcaseUserViewsLoading) return;
          vm.showcaseUserViewsLoading = true;
          vm.showcaseUserViews.splice(0);

          $.ajax({
            url: "/main/analytics/ajax_get_showcase_user_views",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.user_views) {
              vm.showcaseUserViews = data.user_views;
            }

          }).always(() => {
            vm.showcaseUserViewsLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading user views');
          });
        },

        getShowcaseResourceViews(ajaxParams, vm) {
          if (vm.showcaseResourceViewsLoading) return;
          vm.showcaseResourceViewsLoading = true;
          vm.showcaseResourceTotalViews = null;
          vm.showcaseResourceViews.splice(0);

          $.ajax({
            url: "/main/analytics/ajax_get_showcase_resource_views",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.resource_views) {
              vm.showcaseResourceTotalViews = 0;
              vm.showcaseResourceViews = data.resource_views;
              for ( let item of data.resource_views) {
                vm.showcaseResourceTotalViews += item.count ? item.count : 0;
              }
            }

          }).always(() => {
            setTimeout(() => { vm.showcaseResourceViewsLoading = false; }, 1000);

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading file views');
          });
        },

        getShowcaseSharedSummary(ajaxParams, vm) {
          if (vm.showcaseSharedSummaryLoading) return;
          vm.showcaseSharedSummaryLoading = true;
          vm.showcaseSharedByDay.splice(0);
          vm.showcaseTopSharedUsers.splice(0);
          vm.showcaseSharedTotalItems = null;
          vm.showcaseSharedTotalViews = null;

          $.ajax({
            url: "/main/analytics/ajax_get_showcase_shared_views",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.shared_views) {
              vm.showcaseSharedTotalItems = data.shared_views.total_shared;
              vm.showcaseSharedTotalViews = data.shared_views.total_views;
              vm.showcaseSharedByDay = data.shared_views.dates;
              vm.showcaseTopSharedUsers = data.shared_views.users;
            }

          }).always(() => {
            setTimeout(() => {vm.showcaseSharedSummaryLoading = false; }, 1000);

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading shared views');
          });
        },

        getShowcaseSharedResourceViews (ajaxParams, vm) {
          if (vm.showcaseSharedResourcesLoading) return;
          vm.showcaseSharedResourcesLoading = true;
          vm.showcaseSharedResources.splice(0);

          $.ajax({
            url: "/main/analytics/ajax_get_showcase_shared_resources",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.shared_resources)
              vm.showcaseSharedResources = data.shared_resources;

          }).always(() => {
            vm.showcaseSharedResourcesLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading shared files');
          });
        }
      };

    };

    export default {
      name: "AnalyticsForShowcase",
      mixins: [ AnalyticsMixin ],
      components: { AnalyticsTopUsers, AnalyticsTopSlides, AnalyticsTopFiles, AnalyticsNoData, LineChart },
      data () {
        return {
          showcaseReportSummary: {},
          showcaseReportSummaryLoading: false,
          showcaseSummaryTotalPageViews: null,

          showcasePageViews: [],
          showcasePageTotalViews: null,
          showcasePageViewsLoading: false,
          showcasePageViewsHasValues: false,

          showcaseUserViews: [],
          showcaseUserViewsLoading: false,

          showcaseResourceViews: [],
          showcaseResourceTotalViews: null,
          showcaseResourceViewsLoading: false,

          showcaseSharedTotalItems: null,
          showcaseSharedTotalViews: null,
          showcaseSharedSummaryLoading: false,
          showcaseSharedByDayHasValues: false,

          showcaseSharedByDay: [],
          showcaseTopSharedUsers: [],

          showcaseSharedResourcesLoading: false,
          showcaseSharedResources: [],

          sharedByDayChartData: {
            labels: [],
            datasets: [{
                scChartTooltipPrefix: 'Items viewed',
                backgroundColor: 'transparent',
                lineTension: 0,
                borderColor: '#8ed62a',
                pointBackgroundColor: '#8ed62a',
                pointRadius: 4,
                pointHoverRadius: 5,
                pointHoverBorderColor: 'rgba(0, 128, 163, .5)',
                data: []
              },
              {
                scChartTooltipPrefix: 'Items shared',
                backgroundColor: 'transparent',
                lineTension: 0,
                borderColor: '#008ab0',
                pointBackgroundColor: '#008ab0',
                pointRadius: 4,
                pointHoverRadius: 5,
                pointHoverBorderColor: 'rgba(142, 214, 42, .5)',
                data: []
              }]
          },
        };
      },
      props: {
        ajaxParams: {}
      },
      mounted () {
        _dataManager = new DataManager();
        this.loadData();
      },

      methods: {
        analyticsColsMounted () {
          this.$msnry = new Masonry( this.$refs.analyticsColumns, {
            itemSelector: '.analytics-grid-item',
            percentPosition: true
          });
        },
        analyticsColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        analyticsColsUnmounted () {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        },
        paginated () { // triggered masonry update after pagination
          this.$nextTick(() => {
            this.analyticsColsUpdated();
          });
        },
        loadData () {
          _dataManager.getShowcaseSummary(this.ajaxParams, this);
          _dataManager.getShowcasePageViews(this.ajaxParams, this);
          _dataManager.getShowcaseUserViews(this.ajaxParams, this);
          _dataManager.getShowcaseResourceViews(this.ajaxParams, this);
          _dataManager.getShowcaseSharedSummary(this.ajaxParams, this);
          _dataManager.getShowcaseSharedResourceViews(this.ajaxParams, this);
        },
        setPieChartLegend (html) {
          this.pieChartLegend = html
        }
      },

      computed: {
        showcase_deleted () {
          return !!this.showcaseReportSummary.deleted_at;
        },
        scTitle () {
          return this.showcaseReportSummary.title;
        },
      },
      watch: {
        showcaseSharedByDay (nv) {
          if (nv.length === 0)
            return;

          /* vue-chartjs */

          this.sharedByDayChartData.labels.splice(0);
          this.sharedByDayChartData.datasets[0].data.splice(0);
          this.sharedByDayChartData.datasets[1].data.splice(0);
          this.showcaseSharedByDayHasValues = false;

          for (let entry of nv) {
            this.sharedByDayChartData.labels.push(entry['event_day']);
            this.sharedByDayChartData.datasets[0].data.push(Number(entry['count']));
            this.sharedByDayChartData.datasets[1].data.push(Number(entry['share_count']));
            if (Number(entry['count']) > 0) this.showcaseSharedByDayHasValues = true;
            if (Number(entry['share_count']) > 0) this.showcaseSharedByDayHasValues = true;
          }
        },

        ajaxParams () {
          this.loadData();
        }
      }
    }
</script>
