<template>
  <div class="analytics-top-users">

    <AnalyticsNoData :noData="topUsers.length === 0"/>

    <ul v-if="topUsers && topUsers.length > 0" class="list-group list-group-flush">
      <li v-for="(entry, index) in topUsersInPage" class="list-group-item d-flex"
          :key="'topUser' + index">
        <div :class="[hasOpenedCount ? 'col-8' : 'col-10']">
          <router-link v-if="!entry.deleted_at && !entry.user_id"
                       :to="{ name: 'reporting-sharing-history',
                              params: { workshopId: workshopId }}" >
            <ScIcon name="stream" class="me-2"/>
            <span v-sc-tooltip="'See all sharing history'">Sharing Recipient(s)</span>
          </router-link>

          <router-link v-else-if="!entry.deleted_at"
                       :to="{ name: 'reporting-analytics',
                              params: { workshopId: workshopId },
                              query: { uid: entry.user_id, start: startDate, end: endDate }}"
                       :title="'Analytics for ' + entry.user.email">
            <ScIcon name="chartBarFW" class="me-2"/>
            <span>{{ entry.user.display_name }}</span>
          </router-link>

          <span v-else-if="entry.deleted_at">
            {{ entry.user.display_name }}
            <small class="text-muted fw-normal"
                   v-sc-tooltip="'Removed at ' + mainMxScDateFmt(entry.user.deleted_at)">(removed)</small>
          </span>
        </div>
        <div class="col-2 text-end">{{ fmtInt(entry.count) }}</div>
        <div v-if="hasOpenedCount" class="col-2 text-end">{{ fmtInt(entry.opened_count) }}</div>
      </li>
    </ul>
    <div v-if="topUsers && topUsers.length > 0" class="row mt-2">
      <div class="col-12">
        <div class="float-end">
          <AnalyticsPaginator :total="topUsers.length"
                              :currentStart="topUsersStart"
                              @update-start="updateTopUsersStart"></AnalyticsPaginator>
        </div>
        <button @click.prevent="exportTopUsersCsv()" class="btn btn-link text-muted"
                v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
      </div>
    </div>
  </div>
</template>

<script>

    import MainAppMixin from '../../MainAppMixin';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsPaginator from './AnalyticsPaginator.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import AnalyticsNoData from './AnalyticsNoData.vue';

    export default {
      name: "AnalyticsTopUsers",
      mixins: [ AnalyticsMixin, MainAppMixin ],
      components: { AnalyticsPaginator, ScIcon, AnalyticsNoData },
      props: {
        topUsersCountCsvTitle: {type: String, default: null},
        topUsers: {type: Array, default: () => []},
        topUserLinkHref: {type: Boolean, default: false},
        hasOpenedCount: {type: Boolean, default: false},
        csvNameAddition: {type: String, default: null}
      },
      data () {
        return {
          topUsersStart: 0,
        }
      },
      computed: {
        workshopId () {
          return this.mainMxCurrentWorkshopId;
        },
        startDate () {
          return this.$route.query.start;
        },
        endDate () {
          return this.$route.query.end;
        },
        topUsersInPage() {
          if (!this.topUsers) return [];
          return this.topUsers.filter((u, index) => {
            return index >= this.topUsersStart && index < (this.topUsersStart+10);
          });
        }
      },
      methods: {
        updateTopUsersStart (v) {
          this.topUsersStart = v;
          this.$emit('paginated')
        },
        exportTopUsersCsv () {
          let csvLinesArr = [];
          if (this.hasOpenedCount) {
            for (let entry of this.topUsers) {
              csvLinesArr.push([
                entry.user.email,
                entry.user.display_name,
                entry.user.user_workspace_ref,
                this.fmtInt(entry.count),
                this.fmtInt(entry.opened_count)
              ]);
            }
            // remove all whitespaces and lowercase
            let csvName = 'top-sharing-users' + ((this.csvNameAddition) ? '-' + this.csvNameAddition.replace(/\s+/g, '-').toLowerCase() : '');
            this.exportCsv(csvName, ['Email', 'Name', 'User Workshop Reference', this.topUsersCountCsvTitle, 'Opened'], csvLinesArr);
          }
          else {
            for (let entry of this.topUsers) {
              csvLinesArr.push([
                entry.user.email,
                entry.user.display_name,
                entry.user.user_workspace_ref,
                this.fmtInt(entry.count),
              ]);
            }
            // remove all whitespaces and lowercase
            let csvName = 'top-users' + ((this.csvNameAddition) ? '-' + this.csvNameAddition.replace(/\s+/g, '-').toLowerCase() : '');
            this.exportCsv(csvName, ['Email', 'Name', 'User Workshop Reference', this.topUsersCountCsvTitle], csvLinesArr);
          }
        }

      }
    }
</script>
