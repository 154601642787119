<template>
    <div v-if="isLoading || noData" class="d-flex align-items-center justify-content-center"
         style="min-height: 100px;margin-bottom: 30px;">
        <div v-if="isLoading">
            <ScIcon name="spinnerFW" class="text-muted"/>
        </div>
        <div v-else-if="noData"><em class="text-muted">No data</em></div>
    </div>
</template>

<script>
    import ScIcon from '../../../shared/common/ScIcon.vue';

    export default {
        name: "AnalyticsNoData",
        components: {ScIcon},
        props: {
            isLoading: {type: Boolean, default: false},
            noData: {type: Boolean, default: false}
        }
    }
</script>

