<template>
    <ScNormalPageLayout headingPart1="Sharing" headingPart2="History" submenu="sharing">
    <div class="container mt-2 mt-sm-4 mb-5">
        <div class="row" style="min-height: 400px;">

            <SharingHistoryComp :workspaceId="mainMxCurrentWorkshopId" :headerImg="mainMxCurrentWorkshop.header_img"
                                :allowDeleteRecipient="mainMxUwCanPerform('a:delete_any_sharing_hist')" :showCsvExport="true"
                                :userCanPerformListFiles="mainMxUwCanPerform('a:list_files')"
                                @open-user="$router.push({name: 'user-view', params: { workshopId: mainMxCurrentWorkshopId, userId: $event}})"
                                @show-res-info-popover="mainMxShowResInfo($event)"
                                @open-share-content="openShareContent"></SharingHistoryComp>

        </div>
    </div>
    </ScNormalPageLayout>
</template>

<script>

    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import MainAppMixin from '../MainAppMixin';
    import SharingHistoryComp from "../../shared/analytics/SharingHistoryComp.vue";

    export default {
        name: 'SharingHistory',
        mixins: [ MainAppMixin ],
        components: { ScNormalPageLayout, SharingHistoryComp },
        data () {
            return {
                selectedSharedUserId: null,
            };
        },
        methods: {
            openShareContent() {
                this.$router.push({name: 'share-content', params: {workshopId: this.mainMxCurrentWorkshopId}});
            }
        },
    }
</script>
