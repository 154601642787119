<template>
  <div class="analytics-grid-item col-12 col-lg-6 mb-3">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title text-center">Welcome to Analytics!</h5>
        <div class="my-3 ">
          This space can look a little empty until you start really using Showcase. To see the kind of data
          we collect and what it can all look like,
          <a href="https://support.showcaseworkshop.com/help/workshop-analytics" target="_blank">
            check out our guide to analytics</a>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "AnalyticsEmptyTeaser"
  }

</script>

