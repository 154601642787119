<template>
    <div class="row mx-sm-2 my-2 py-2 px-sm-2 border-bottom">
        <div class="col-3 col-sm-2 text-center pt-2">
            <ScIcon :name="getIconName(activity_key)" size="2" v-sc-tooltip="description"/>
        </div>
        <div class="col-9 col-sm-10 mb-2">
            <strong class="d-block">{{ user ? user.display_name : 'System' }}</strong>
            {{ generatedActivityText }}
            <small class="d-block">{{ mainMxScDateFmt(activity_date) }}</small>
        </div>
    </div>
</template>

<script>

    import MainAppMixin from "../MainAppMixin";
    import ScIcon from "../../shared/common/ScIcon.vue";

    let _activityIconNames = {
        'default': 'circleSmall',
        'edit_presentation': 'editSmall',
        'apply_draft': 'rocketSmall',
        'discard_draft': 'undoSmall',
        'new_presentation': 'plusSmall',
        'delete_presentation': 'minusCircleSmall',
        'publish_presentation': 'rocketSmall',
        'unpublish_presentation': 'cogSmall',
        'archive_presentation': 'boxArchiveSmall',
        'new_workspace': 'plusSmall',
        'restore_showcase': 'cloneSmall',
        'copy_presentation': 'cloneSmall',
        'import_showcase': 'cloudUploadSmall',
        'export_presentation': 'fileSmall',
        'get_presentation_metadata': 'arrowCircleDownSmall',
        'add_user': 'userPlusSmall',
        'added_user': 'userPlusSmall',
        'invite_user': 'userPlusSmall',
        'added_to_group': 'userPlusSmall',
        'accepted_invite': 'userPlusSmall',
        'remove_user': 'userTimesSmall',
        'removed_from_group': 'userTimesSmall',
        'create_prospect': 'userPlusSmall',
        'edit_prospect': 'userSmall',
        'billing': 'cogSmall',
        'email_bounced': 'frownSmall',
        'webhook_error': 'frownSmall',
        'usersync_csv_begin': 'usersSmall',
        'usersync_csv_end': 'usersSmall',
        'change_rolelevel': 'userEditSmall',
        'added_label': 'tagSmall',
        'removed_label': 'tagSmall',
        'rename_showcase': 'editSmall'
    };

    export default {
        name: "ActivityListItem",
        components: {ScIcon},
        mixins: [ MainAppMixin ],
        props: {
            description: {type: String, default: null},
            activity_key: {type: String, default: null},
            activity_date: {type: String, default: null},
            user: {type: Object, default: null},
            generatedActivityText: {type: String, default: null}
        },
        methods: {
            getIconName(key) {
                return (_activityIconNames[key]) ? _activityIconNames[key] : _activityIconNames['default'];
            }
        },
    }
</script>
