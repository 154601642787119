import _ from 'underscore'; // isObject, isArray, each, size

export default {
    methods: {
        standardiseFormJson: function(obj) {
            /*
            We have to support a nice display for the following JSON structure styles:

            style1 = {"Field One Name": "Field One Value",
                      "Field Two Name": {"sub field a name": "sub field a value"},
                      "Field Three Name": ""};

            style2 = [{"Field One Name": "Field One Value"},
                      {"Field Two Name": {"sub field a name": "sub field a value"}},
                      {"Field Three Name": ""}];

            style3 = [{"name":"Field One Name", "value": "Field One Value"},
                      {"name":"Field Two Name", "value": {"sub field a name": "sub field a value"}},
                      {"name":"Field Three Name", "value": ""}];

            style4 = [{"name": "Field one name", "value": "Field one value"},
                     {"name":"Field Two Name", "value": {"sub field a name": "sub field a value"}},
                     {"name":"Field Three"}];

            Standardised style:

            [{"name": "Field one name", "value": "Field one value"},
             {"name":"Field Two Name", "value": {"sub field a name": "sub field a value"}}];

            Field one name: Field one value
             */
            let toNvPairs = function(obj) {
                let nvPairs = [];
                if (_.isObject(obj)) {
                    if (_.isArray(obj)) {
                       _.each(obj, function (value) {
                           if (typeof value.value === 'object' && typeof value.value.length === 'number')
                               value.value = value.value.length > 0 ? value.value : ' ';
                           nvPairs = nvPairs.concat(toNvPairs(value));
                        });
                    } else if (_.size(obj) === 2 && obj.name && obj.value) {
                        nvPairs.push({name: obj.name, value: _.isObject(obj.value) ? toNvPairs(obj.value) : obj.value});
                    } else {
                        _.each(obj, function (value, key) {
                            nvPairs.push({name: key, value: _.isObject(value) ? toNvPairs(value) : value});
                        });
                    }
                }
                return nvPairs;
            };
            return toNvPairs(obj);
        }
    }
};