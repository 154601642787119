<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-group-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal">Group Analytics <strong>{{ groupName }}</strong></h2>

      <div ref="analyticsColumns" class="row"
           @vnode-mounted="analyticsColsMounted"
           @vnode-updated="analyticsColsUpdated"
           @vnode-unmounted="analyticsColsUnmounted">

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Summary</h5>
              <AnalyticsNoData :isLoading="groupReportSummaryLoading"/>

              <ul v-if="!groupReportSummaryLoading" class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(groupReportSummary.total_page_views) }}</strong>
                  </div>
                  Slide views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(groupReportSummary.total_resource_views) }}</strong>
                  </div>
                  File views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(groupReportSummary.total_shared_items) }}</strong>
                  </div>
                  Shared items
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top users</h5>

              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="groupReportSummaryLoading"/>
              <AnalyticsTopUsers v-if="groupReportSummary && !groupReportSummaryLoading"
                                 :csvNameAddition="groupName"
                                 topUsersCountCsvTitle="Slide Views"
                                 :hasOpenedCount="false"
                                 :topUsers="groupReportSummary.top_users"
                                 @paginated="paginated"></AnalyticsTopUsers>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top presentations</h5>

              <div class="d-flex justify-content-end">
                <div class="text-muted">File views</div>
              </div>

              <AnalyticsNoData :isLoading="groupReportSummaryLoading"/>
              <AnalyticsTopShowcases v-if="!groupReportSummaryLoading && groupReportSummary"
                                     :csvNameAddition="groupName"
                                     :topShowcases="groupReportSummary.top_showcases"
                                     @paginated="paginated"></AnalyticsTopShowcases>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top files</h5>

              <div class="d-flex justify-content-end">
                <div class="text-muted">File views</div>
              </div>

              <AnalyticsNoData :isLoading="showcaseResourceViewsLoading"/>
              <AnalyticsTopFiles v-if="!showcaseResourceViewsLoading"
                                 :csvNameAddition="groupName"
                                 topFilesCountCsvTitle="Views"
                                 :topFiles="showcaseResourceViews"
                                 @paginated="paginated"></AnalyticsTopFiles>
            </div>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
    import $ from 'jquery';
    import Masonry from 'masonry-layout';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import AnalyticsTopUsers from './AnalyticsTopUsers.vue';
    import AnalyticsTopShowcases from './AnalyticsTopShowcases.vue';
    import AnalyticsTopFiles from './AnalyticsTopFiles.vue';

    let _testNotEnoughData = false;

    let DataManager = function() {
      return {
        getGroupSummary(ajaxParams, vm) {
          if (vm.groupReportSummaryLoading) return;
          vm.groupReportSummary = {};
          vm.groupReportSummaryLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_group_report",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data)
              vm.groupReportSummary = data;

          }).always(() => {
            vm.groupReportSummaryLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading group summary');
          });
        },
        getGroupTopResourceViews(ajaxParams, vm) {
          if (vm.showcaseResourceViewsLoading) return;
          vm.showcaseResourceViewsLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_group_resource_views",
            data: ajaxParams

          }).done((data) => {

            //console.log(data, 'DATA');
            if (!_testNotEnoughData || data || data.resource_views) {
              for (let res of data.resource_views) {
                vm.showcaseResourceViews.push(res);
              }
              vm.showcaseResourceViews.sort(function(a, b){ return b.count - a.count; });
            }

          }).always(() => {
            setTimeout(() => { vm.showcaseResourceViewsLoading = false; }, 1000);

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading group views');
          });
        },
      };

    };

    let _dataManager = null;

    export default {
      name: "AnalyticsForGroup",
      mixins: [ AnalyticsMixin ],
      components: { AnalyticsTopUsers, AnalyticsTopShowcases, AnalyticsTopFiles, AnalyticsNoData },
      props: {
        ajaxParams: {}
      },
      data () {
        return {
          groupReportSummary: {},
          groupReportSummaryLoading: false,
          showcaseResourceViews: [],
          showcaseResourceViewsLoading: false,
        };
      },
      mounted () {
        _dataManager = new DataManager();
        this.loadData();

      },

      methods: {
        analyticsColsMounted () {
          this.$msnry = new Masonry( this.$refs.analyticsColumns, {
            itemSelector: '.analytics-grid-item',
            percentPosition: true
          });
        },
        analyticsColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        analyticsColsUnmounted () {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        },
        paginated () { // triggered masonry update after pagination
          this.$nextTick(() => {
            this.analyticsColsUpdated();
          });
        },
        loadData () {
          _dataManager.getGroupSummary(this.ajaxParams, this);
          _dataManager.getGroupTopResourceViews(this.ajaxParams, this);
        },
        getPagePercentageOfTotal (entry) {
          return Math.round((entry.count/this.groupReportSummary.total_page_views) *100);
        }
      },
      computed: {
        groupName () {
          return this.groupReportSummary.group_name;
        }
      },
      watch: {
        ajaxParams () {
          this.loadData();
        }
      }
    }
</script>
