<template>
  <ScNormalPageLayout headingPart2="Analytics" submenu="reporting">
    <div class="container my-5" v-if="startDate">
      <div class="row mb-4">
        <div v-if="(userId && showcaseId) || slideId" class="col-md-3 col-sm-12 h4 fw-normal">
          <router-link :to="{ name: 'reporting-analytics',
                              params:{ workshopId: workshopId },
                              query: { scid: showcaseId, start: fmtDateForUrl(startDate), end: fmtDateForUrl(endDate) } }">
            <ScIcon name="angleLeft" class="me-2"/>
            Presentation
          </router-link>
        </div>
        <div v-else-if="groupId || userId || showcaseId || fileId" class="col-md-3 col-sm-12 h4 fw-normal">
          <router-link :to="{ name: 'reporting-analytics',
                              query: { start: fmtDateForUrl(startDate), end: fmtDateForUrl(endDate) }}">
            <ScIcon name="angleLeft" class="me-2"/>
            Workshop
          </router-link>
        </div>

        <form action="" class="col text-center text-lg-end" >
          <ScReportingRangeDatePicker @select-date-range="selectDateRange"
                                      v-if="!!startDatepicker"
                                      :routeDateFrom="startDatepicker" :routeDateTo="endDatepicker"/>

          <ScIcon name="questionCircle" class="ms-2 text-muted"
                  v-sc-tooltip="'Max range between dates is 1 year. Showcase retains data for the past 3 years; data older than this is automatically deleted.'"/>
        </form>
      </div>

      <AnalyticsForFile v-if="fileId && !showcaseId"
                         :ajaxParams="getAjaxParams" ></AnalyticsForFile>

      <AnalyticsForSlide v-if="!fileId && slideId && showcaseId"
                         :ajaxParams="getAjaxParams" ></AnalyticsForSlide>

      <AnalyticsForWorkshop v-if="!fileId && !userId && !showcaseId && !groupId && !slideId"
                            :ajaxParams="getAjaxParams" ></AnalyticsForWorkshop>

      <AnalyticsForGroup v-if="!fileId && !slideId && groupId"
                         :ajaxParams="getAjaxParams" ></AnalyticsForGroup>

      <AnalyticsForShowcase v-if="!fileId && !slideId && showcaseId && !userId"
                            :ajaxParams="getAjaxParams" ></AnalyticsForShowcase>

      <AnalyticsForUser v-if="!fileId && !slideId && userId && !showcaseId"
                        :ajaxParams="getAjaxParams" ></AnalyticsForUser>

      <AnalyticsForUserShowcase v-if="!fileId && !slideId && userId && showcaseId"
                                :ajaxParams="getAjaxParams" ></AnalyticsForUserShowcase>

    </div>
  </ScNormalPageLayout>
</template>

<script>

    import {subDays, parseISO} from 'date-fns';
    import ScReportingRangeDatePicker from '../ScReportingRangeDatePicker.vue';
    import ScNormalPageLayout from '../../global/ScNormalPageLayout.vue';
    import AnalyticsMixin from "./AnalyticsMixin";
    import AnalyticsForWorkshop from './AnalyticsForWorkshop.vue';
    import AnalyticsForGroup from './AnalyticsForGroup.vue';
    import AnalyticsForShowcase from './AnalyticsForShowcase.vue';
    import AnalyticsForUser from './AnalyticsForUser.vue';
    import AnalyticsForUserShowcase from './AnalyticsForUserShowcase.vue';
    import MainAppMixin from "../../MainAppMixin";
    import AnalyticsForFile from "./AnalyticsForFile.vue";
    import AnalyticsForSlide from "./AnalyticsForSlide.vue";
    import ScIcon from '../../../shared/common/ScIcon.vue';


    export default {
      name: "Analytics",
      mixins: [AnalyticsMixin, MainAppMixin],
      components: { ScNormalPageLayout, ScReportingRangeDatePicker, AnalyticsForWorkshop, AnalyticsForGroup,
        AnalyticsForShowcase, AnalyticsForUser, AnalyticsForUserShowcase, AnalyticsForFile, AnalyticsForSlide, ScIcon },
      data () {
        return {
          defaultStartDate: subDays(new Date(), 30),
          defaultEndDate: new Date(),
          startDatepicker: null,
          endDatepicker: null,
        }
      },
      mounted () {
        //console.log('mounted analytics', this.$route.query);
        this.startDatepicker = this.startDate;
        this.endDatepicker = this.endDate;
      },
      methods: {
        selectDateRange (dateArr) {
          let [start, end] = dateArr;
          this.startDatepicker = start;
          this.endDatepicker = end;
          //console.log('selectDateRange', start, end);

          let queryList = {};
          if (this.$route.query.scid) queryList.scid = this.$route.query.scid;
          if (this.$route.query.gid) queryList.gid = this.$route.query.gid;
          if (this.$route.query.uid) queryList.uid = this.$route.query.uid;
          if (this.$route.query.fid) queryList.fid = this.$route.query.fid;
          if (this.$route.query.sid) queryList.sid = this.$route.query.sid;
          queryList.start = this.fmtDateForUrl(start);
          queryList.end = this.fmtDateForUrl(end);

          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: queryList
          });
        },
      },
      computed: {
        workshopId () {
          return this.mainMxCurrentWorkshopId;
        },
        userId () {
          return this.$route.query.uid;
        },
        showcaseId () {
          return this.$route.query.scid;
        },
        groupId () {
          return this.$route.query.gid;
        },
        fileId () {
          return this.$route.query.fid;
        },
        slideId () {
          return this.$route.query.sid;
        },
        startDate () {
          //console.log('analytics.startDate', this.$route.query.start, parseISO(this.$route.query.start), this.defaultStartDate);
          return (this.$route.query.start) ? parseISO(this.$route.query.start) : this.defaultStartDate;
        },
        endDate () {
          return (this.$route.query.end) ? parseISO(this.$route.query.end) : this.defaultEndDate;
        },
        getAjaxParams () {
          return {
            workspace_id: this.workshopId,
            showcaseId: this.showcaseId,
            userId: this.userId,
            groupId: this.groupId,
            fileId: this.fileId,
            slideId: this.slideId,
            startDate: this.fmtDateForUrl(this.startDate),
            endDate: this.fmtDateForUrl(this.endDate)
          };
        }
      },
    }
</script>
