<template>
  <div v-if="isLoaded" class="analytics-top-files">

    <AnalyticsNoData :noData="topFiles.length === 0" />

    <ul v-if="topFiles && topFiles.length > 0" class="list-group list-group-flush">
      <li v-for="(entry, idx) in topFilesForPage" :key="'tf-table' + idx"
          class="list-group-item d-flex ps-0">
        <div class="col-4 text-center">
          <img :src="getThumbUrl(entry)" style="width: 100px; height: 100px;" alt="Thumbnail" />
        </div>
        <div :class="['my-auto', hasOpenedCount ? 'col-4' : 'col-5']">
          <router-link v-if="!isShowcaseForm(entry) && entry.type !== 'showcase_share' && entry.content_type !== 'weburl'"
                       :to="{ name: 'reporting-analytics',
                              params:{ workshopId: mainMxCurrentWorkshopId },
                              query: { fid: entry.id, start: startDate, end: endDate  }
                             }" :title="'Analytics for ' + entry.name">
            <ScIcon name="chartBarFW" class="me-2"/>{{ entry.name }}</router-link>
          <a v-if="infoPopoverAvailable(entry.content_type)" href="#" v-sc-trackevent="'analytics:resource_info_popover'"
             class="hidden-print text-muted" @click.prevent="infoPopoverOpen(entry.id)">
            <ScIcon name="infoCircle" class="ms-2"/>
          </a>
          <a v-if="!isShowcaseForm(entry) && entry.type !== 'showcase_share' && entry.content_type === 'weburl'"
             :href="entry.url" target="_blank" :title="'Go to ' + entry.name">
            <ScIcon name="link" style="display: inherit"/>
            <span class="ms-2">{{ entry.name }}</span>
          </a>
          <span v-if="entry.type === 'showcase_share'">Full presentation</span>
          <span v-if="isShowcaseForm(entry)">Showcase Form</span>
          <span v-if="entry.type !== 'showcase_share'"><br/>{{ entry.type.split('_')[0] }}</span>
        </div>
        <div :class="[' text-end my-auto', hasOpenedCount ? 'col-2' : 'col-3']">{{ fmtInt(entry.count) }}</div>
        <div v-if="hasOpenedCount"
             class="col-2 text-end my-auto">{{ fmtInt(entry.opened_count) }}</div>
      </li>
    </ul>
    <div v-if="topFiles && topFiles.length > 0" class="row">
      <div class="col-12">
        <div class="float-end">
          <AnalyticsPaginator :total="topFiles.length"
                              :currentStart="topFilesStart"
                              @update-start="updateTopFilesStart"></AnalyticsPaginator>
        </div>
        <button @click.prevent="exportTopFilesCsv()" class="btn btn-link text-muted"
                v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
      </div>
    </div>
  </div>
</template>

<script>
    import _ from 'underscore'; // contains, each, isUndefined
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsPaginator from './AnalyticsPaginator.vue';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import MainAppMixin from "../../MainAppMixin";
    import ScIcon from '../../../shared/common/ScIcon.vue';

    export default {
      name: "AnalyticsTopFiles",
      mixins: [ AnalyticsMixin, MainAppMixin ],
      components: { AnalyticsPaginator, AnalyticsNoData, ScIcon },
      props: {
        topFilesShowcaseId: {type: Number, default: null},
        topFiles: {type: Array, default: () => []},
        topFilesCountCsvTitle: {type: String, default: null},
        hasOpenedCount: {type: Boolean, default: false},
        csvNameAddition: {type: String, default: null}
      },
      data () {
        return {
          topFilesStart: 0
        }
      },
      methods: {
        updateTopFilesStart (v) {
          this.topFilesStart = v;
          this.$emit('paginated');
        },
        isShowcaseForm (res) {
          return res.type && res.type === 'url' && res.name && res.name.indexOf('showcaseform:') === 0;
        },
        getThumbUrl (res) {
          if (res && res.type === 'url')
            return 'https://cdn.showcaseworkshop.com/showcase-icons/7.1.1/200x200_all.png';
          return res.thumb_url;
        },
        infoPopoverAvailable (ct) {
          return this.mainMxUwCanPerform('a:list_files') &&
              _.contains(['image','document','movie'], ct);
        },
        infoPopoverOpen (resId) {
          this.mainMxShowResInfo(resId);
        },
        exportTopFilesCsv () {
          let csvLinesArr = [];
          let vm = this;

          if (this.hasOpenedCount) {
            _.each(this.topFiles, function (entry) {
              csvLinesArr.push([
                entry.name,
                entry.type,
                vm.fmtInt(entry.count),
                vm.fmtInt(entry.opened_count)
              ]);
            });
            // remove all whitespaces and lowercase
            let csvName = 'top-shared-items' + ((this.csvNameAddition) ? '-' + this.csvNameAddition.replace(/\s+/g, '-').toLowerCase() : '');
            this.exportCsv(csvName, ['Item', 'Type', this.topFilesCountCsvTitle, 'Opened'], csvLinesArr);

          } else {
            _.each(this.topFiles, function (entry) {
              csvLinesArr.push([
                entry.name,
                entry.type,
                vm.fmtInt(entry.count),
              ]);
            });
            // remove all whitespaces and lowercase
            let csvName = 'top-files' + ((this.csvNameAddition) ? '-' + this.csvNameAddition.replace(/\s+/g, '-').toLowerCase() : '');
            this.exportCsv(csvName, ['File', 'Type', this.topFilesCountCsvTitle], csvLinesArr);
          }
        }
      },
      computed: {
        isLoaded () {
          return !_.isUndefined(this.topFiles);
        },
        topFilesForPage() {
          if (!this.topFiles) return [];
          return this.topFiles.filter((entry, idx) => {
            return idx >= this.topFilesStart && idx < (this.topFilesStart+10);
          });
        },
        startDate () {
          return this.$route.query.start;
        },
        endDate () {
          return this.$route.query.end;
        },
      }
    }
</script>
