<template>
    <div class="row pb-3 mx-0 mx-sm-2 my-2 px-0 px-sm-2 py-2 border-bottom">
        <div class="w-100">
            <strong>{{ form_name }}</strong>
        </div>
        <div class="w-100">
            Submitted by
            <span v-if="shared && shared.shared_user">
                <template v-if="shared.shared_user.name">"{{shared.shared_user.name}}"</template>
                <template v-else-if="shared.shared_user.email">{{shared.shared_user.email}}</template>
            </span>
            <span v-else-if="user">"{{user.display_name}}"</span>
            <span v-else>System</span>
            at {{ mainMxScDateFmt(date_entered) }} via
            <span v-if="data_type === 'rest'">REST API</span>
            <span v-if="data_type === 'generic'">JavaScript API</span>
            <span v-if="data_type === 'form'">Showcase Form</span>
        </div>

        <div class="sc-form-fields w-100 small" v-html="formattedInfodict"></div>

        <div class="w-100 mt-3">
            <small class="d-inline-block text-muted">{{ presentation_title }} / {{ guid }}</small>

            <div class="d-block float-end">
                <span v-if="delete_pending"><ScIcon name="spinnerFW" class="text-muted"/></span>
                <a v-if="!delete_pending" href="#" @click.prevent="deleteItem"><ScIcon name="trashAlt" class="text-muted" v-sc-tooltip="'Delete item'"/></a>
            </div>
        </div>

    </div>
</template>

<script>

    import $ from 'jquery';
    import _ from 'underscore'; // isArray, isObject
    import StandardiseJSONMixin from './StandardiseJSONMixin';
    import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
    import MainAppMixin from '../MainAppMixin';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: "FormResultItem",
        emits: ['delete-result'],
        mixins: [ StandardiseJSONMixin, MainAppMixin ],
        components: {ScIcon},
        props: {
            guid: {type: String, default: null},
            infodict: {type: String, default: null},
            user_id: {type: Number, default: null},
            user: {type: Object, default: null},
            shared: {type: Object, default: null},
            form_name: {type: String, default: null},
            data_type: {type: String, default: null},
            date_entered: {type: String, default: null},
            presentation_title: {type: String, default: null},
            delete_pending: {type: Boolean, default: false},
        },
        computed: {
            deleted () {
                return false;
            },
            formattedInfodict () {
                return this.getFormattedInfodictHtml(this.infodict)
            }
        },
        methods: {
            deleteItem () {
                ScConfirmModal2.modal('Delete this form record?').then(() => {
                    this.$emit('delete-result', this.guid);
                }, () => {});
            },
            getFormattedInfodictHtml (data) {
                if (data && data.length > 4 && (data.charAt(0) === '[' || data.charAt(0) === '{')) {
                    let parsed;
                    try {
                        parsed = JSON.parse(data);
                    } catch (e) {
                        parsed = {};
                    }

                    let standardisedJson = this.standardiseFormJson(parsed);

                    let $formatFields = function (fieldsArr) {
                        if (!_.isArray(fieldsArr))
                            return null;
                        let $fields = $('<div>', {"class": "w-100 mt-3 mb-1 justify-content-start sc-form-table h6 fw-normal"});
                        for (let value of fieldsArr) {
                            let $field = $('<div>', {"class": "row"});
                            let $fieldName = $('<div>', {"class": "col-4 my-1 fw-bold"}).text(value.name);
                            let $fieldValue = $('<div>', {"class": "col-8 my-1"});
                            if (_.isObject(value.value))
                                $fieldValue.append($formatFields(value.value));
                            else
                                $fieldValue.append($.parseHTML('<span>' + value.value + '</span>'));

                            $field.append($fieldName).append($fieldValue);
                            $fields.append($field);
                        }

                        return $('<div>').append($fields);
                    };
                    return $formatFields(standardisedJson).html();

                } else {
                    return data;
                }
            }
        }
    }

</script>
