import _ from 'underscore'; // each
import { format, subDays } from 'date-fns';
import Papa from 'papaparse';
import ScCommonUtil from "../../../shared/common/ScCommonUtil";
import ScCsvUtil from "../../global/ScCsvUtil";


export default {
  methods: {
    parseClientIcon (item) {
      return ScCommonUtil.parseIconFromClientName(item.client_name);
    },
    fmtDateForUrl (date) {
      if (typeof date !== 'object') console.warn('fmtDateForUrl called with a non object', date);
      return format(date, 'yyyy-MM-dd');
    },
    fmtInt (x) {
      if (x === undefined || x === null) return '';
      return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    fmtDuration (x) {
      if (x === undefined || x === null) return '';
      let mins = Math.round(x/1000/60);
      if (mins === 0) return '';
      return this.fmtInt(mins);
    },
    exportCsv (fileNameComp, headerArray, linesArray) {
      let fileName = `${ fileNameComp }-${ format(new Date(), 'yyyyMMddHHmm') }.csv`;
      let csvData = [headerArray];
      _.each(linesArray, function (modLine) {
        csvData.push(modLine);
      });
      let finalCsvText = Papa.unparse(csvData);
      ScCsvUtil.deliverCsv(finalCsvText, fileName);
    },
    isDefaultDateRange (start, end) {
      let startDatepicker = subDays(new Date(), 30);
      let endDatepicker = new Date();
      return start === this.fmtDateForUrl(startDatepicker) && end === this.fmtDateForUrl(endDatepicker);
    }
  }
}