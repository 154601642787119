<template>
  <div class="analytics-top-users">

    <AnalyticsNoData :noData="topVideoUsers.length === 0"/>

    <ul v-if="topVideoUsers && topVideoUsers.length > 0" class="list-group list-group-flush">
      <li v-for="(entry, index) in topUsersInPage" class="list-group-item d-flex"
          :key="'topUser' + index">
        <div class="col-10">
          <router-link v-if="!entry.deleted_at && !entry.user_id"
                       :to="{ name: 'reporting-sharing-history',
                              params: { workshopId: workshopId }}" >
            <ScIcon name="stream" class="me-2"/>
            <span v-sc-tooltip="'See all sharing history'">Sharing Recipient(s)</span>
          </router-link>

          <router-link v-else-if="!entry.deleted_at"
                       :to="{ name: 'reporting-analytics',
                              params: { workshopId: workshopId },
                              query: { uid: entry.user_id, start: startDate, end: endDate }}"
                       :title="'Analytics for ' + entry.user.email">
            <ScIcon name="chartBarFW" class="me-2"/>
            <span>{{ entry.user.display_name }}</span>
          </router-link>

          <span v-else-if="entry.deleted_at">
            {{ entry.user.display_name }}
            <small class="text-muted fw-normal"
                   v-sc-tooltip="'Removed at ' + mainMxScDateFmt(entry.user.deleted_at)">(removed)</small>
          </span>
        </div>
        <div class="col-2 text-end">{{ ScDateUtil.formatVideoDurationSeconds(entry.total_duration_seconds) }}</div>
      </li>
    </ul>
    <div v-if="topVideoUsers && topVideoUsers.length > 0" class="row mt-2">
      <div class="col-12">
        <div class="float-end">
          <AnalyticsPaginator :total="topVideoUsers.length"
                              :currentStart="topUsersStart"
                              @update-start="updateTopUsersStart"></AnalyticsPaginator>
        </div>
        <button @click.prevent="exportTopUsersCsv()" class="btn btn-link text-muted"
                v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
      </div>
    </div>
  </div>
</template>

<script>

    import MainAppMixin from '../../MainAppMixin';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsPaginator from './AnalyticsPaginator.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import ScDateUtil from "../../global/ScDateUtil";
    import $ from "jquery";
    import ScNotification from "@/shared/common/ScNotification.vue";
    import {format} from "date-fns";

    export default {
      name: "AnalyticsTopVideoUsers",
      mixins: [ AnalyticsMixin, MainAppMixin ],
      components: { AnalyticsPaginator, ScIcon, AnalyticsNoData },
      props: {
        topVideoUsersCountCsvTitle: {type: String, default: null},
        topVideoUsers: {type: Array, default: () => []},
        topVideoUserLinkHref: {type: Boolean, default: false},
        hasVideoOpenedCount: {type: Boolean, default: false},
        csvNameAddition: {type: String, default: null},
        ajaxParams: {}
      },
      data () {
        return {
          topUsersStart: 0,
        }
      },
      computed: {
        ScDateUtil() {
          return ScDateUtil
        },
        workshopId () {
          return this.mainMxCurrentWorkshopId;
        },
        startDate () {
          return this.$route.query.start;
        },
        endDate () {
          return this.$route.query.end;
        },
        topUsersInPage() {
          if (!this.topVideoUsers) return [];
          return this.topVideoUsers.filter((u, index) => {
            return index >= this.topUsersStart && index < (this.topUsersStart+10);
          });
        }
      },
      methods: {
        updateTopUsersStart(v) {
          this.topUsersStart = v;
          this.$emit('paginated')
        },
        exportTopUsersCsv() {
          $.ajax({
            url: "/main/analytics/ajax_get_workshop_video_csv",
            data: this.ajaxParams
          }).done((data) => {
            let sortTopVideoUsers = {};
            for (let index = 0; index < this.topVideoUsers.length; index++) {
              sortTopVideoUsers[this.topVideoUsers[index].user_id] = index;
            }

            for (let user of data.users) {
              user['sort'] = sortTopVideoUsers[user.user_id];
            }

            data.users.sort((a, b) => {
              if (a.sort < b.sort) {
                return -1;
              } else if (a.sort > b.sort) {
                return 1;
              } else {
                return (new Date(b.inserted_date) - new Date(a.inserted_date));
              }
            });

            let csvLinesArr = [];
            for (let entry of data.users) {
              csvLinesArr.push([
                entry.user.display_name,
                format(new Date(entry.inserted_date), "yyyy-MM-dd hh:mm:ss"),
                ScDateUtil.formatVideoDurationSeconds(entry.duration_seconds),
                entry.client_name
              ]);
            }

            let csvName = this.topVideoUsersCountCsvTitle + ((this.csvNameAddition) ? '-' + this.csvNameAddition.replace(/\s+/g, '-').toLowerCase() : '');
            this.exportCsv(csvName, ['Username', 'Open Time', 'Viewed Time', 'Client name'], csvLinesArr);
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading csv data error!');
          });
        }
      }
    }
</script>
