<template>
  <div v-if="isLoaded" class="analytics-top-slides" >

    <AnalyticsNoData :noData="topSlides.length === 0" />

    <ul v-if="topSlides && topSlides.length > 0" class="list-group list-group-flush">
      <li v-for="(entry, index) in topSlidesInPage" :key="'ts-table' + index"
          class="list-group-item d-flex">
        <div class="col-4 my-auto">
          <router-link v-if="!linkDisabled && !entry.deleted"
                       :to="{ name: 'outline',
                              params: { workshopId: mainMxCurrentWorkshopId, showcaseId: topSlidesShowcaseId },
                              query: {pid: entry.id}}"
                       v-sc-tooltip="'Go to outline'">
            <img alt="Thumbnail" :src="entry.thumb_url" class="thumb-analytics" :style="thumbDimsStyleObject(entry.thumb_url)"/>
          </router-link>

          <span v-else>
            <img alt="Thumbnail" :src="entry.thumb_url" class="thumb-analytics" :style="thumbDimsStyleObject(entry.thumb_url)"/>
          </span>
        </div>

        <div class="col-8 mx-auto">
          <div>
            <router-link :to="{ name: 'reporting-analytics',
                              params:{ workshopId: mainMxCurrentWorkshopId },
                              query: { sid: entry.id, scid: topSlidesShowcaseId, start: startDate, end: endDate  }
                             }"
                         :title="`Analytics for ${ entry.alpha_num_name } ${ entry.name }`">
              <strong class="me-1">{{ entry.alpha_num_name }}</strong>
              <span v-if="entry.name">{{ entry.name }}</span>
            </router-link>
            <span v-if="entry.deleted" class="text-muted"> (deleted)</span>
          </div>
          <div class="my-1"><span class="h3 fw-normal">{{ fmtInt(entry.count) }}</span> views</div>
          <div class="progress">
            <div class="progress-bar" :style="{width: getPagePercentageOfTotal(entry) + '%', 'background-color': '#0089B2'}"></div>
          </div>
        </div>
      </li>
    </ul>

    <div v-if="topSlides && topSlides.length > 0" class="row">
      <div class="col-12">
        <div class="float-end">
          <AnalyticsPaginator :total="topSlides.length"
                              :currentStart="topSlidesStart"
                              @update-start="updateTopSlidesStart"></AnalyticsPaginator>
        </div>
        <button @click.prevent="exportTopSlidesCsv()" class="btn btn-link text-muted"
                v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
      </div>
    </div>

  </div>

</template>

<script>
    import _ from 'underscore'; // isUndefined, each
    import AnalyticsMixin from "./AnalyticsMixin";
    import AnalyticsPaginator from "./AnalyticsPaginator.vue";
    import AnalyticsNoData from "./AnalyticsNoData.vue";
    import MainAppMixin from "../../MainAppMixin";
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import ScCommonUtil from '../../../shared/common/ScCommonUtil';

    export default {
      name: "AnalyticsTopSlides",
      mixins: [ MainAppMixin, AnalyticsMixin ],
      components: { AnalyticsPaginator, AnalyticsNoData, ScIcon },
      props: {
        topSlides: {type: Array, default: () => []},
        topSlidesTotalViews: {type: Number, default: null},
        linkDisabled: {type: Boolean, default: false},
        csvNameAddition: {type: String, default: null}
      },
      data () {
        return {
          topSlidesStart: 0,
        }
      },
      mounted () {
        this.topSlidesStart = 0;
      },
      methods: {
        updateTopSlidesStart (v) {
          this.topSlidesStart = v;
          this.$emit('paginated'); // trigger masonry update in parent
        },
        getPagePercentageOfTotal (entry) {
          return Math.round((entry.count/this.topSlidesTotalViews)*100);
        },
        exportTopSlidesCsv () {
          let csvLinesArr = [];
          let vm = this;
          _.each(this.topSlides, function(entry) {
            csvLinesArr.push([
              entry.alpha_num_name,
              entry.name ? entry.name : '',
              entry.deleted ? 'Yes' : 'No',
              vm.fmtInt(entry.count)
            ]);
          });
          // remove all whitespaces and lowercase
          let csvName = 'top-slides' + ((this.csvNameAddition) ? '-' + this.csvNameAddition.replace(/\s+/g, '-').toLowerCase() : '');
          this.exportCsv(csvName, ['Slide', 'Slide Name', 'Deleted', 'Slide Views'], csvLinesArr);
        },
        thumbDimsStyleObject (thumbUrl) {
          let maxWidth = 115; // define max-width for thumbs
          let thumb = ScCommonUtil.getThumbDimensionsFromUrl(thumbUrl, maxWidth, 86);
          let scaleFactor = (maxWidth / thumb.w);

          // landscape = scale thumb height && portrait = original thumb height
          let calcHeight = (thumb.w < maxWidth) ? thumb.h : thumb.h * scaleFactor;
          let calcWidth = (maxWidth < thumb.w) ? maxWidth : thumb.w ;

          return {
            height: calcHeight + 'px',
            width: calcWidth + 'px'
          }
        }
      },
      computed: {
        isLoaded () {
          return !_.isUndefined(this.topSlides);
        },
        startDate () {
          return this.$route.query.start;
        },
        endDate () {
          return this.$route.query.end;
        },
        topSlidesShowcaseId () {
          return this.$route.query.scid;
        },
        topSlidesInPage () {
          if (!this.topSlides) return [];
          return this.topSlides.filter((entry, index) => {
            return index >= this.topSlidesStart && index < (this.topSlidesStart + 10);
          });
        }
      },
    }
</script>