<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-user-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal">User Analytics <strong>{{ userDisplayName }}</strong>
        <span v-if="user && user.user_workspace_ref"> ({{ user.user_workspace_ref }})</span>
      </h2>

      <div ref="analyticsColumns" class="row"
           @vnode-mounted="analyticsColsMounted"
           @vnode-updated="analyticsColsUpdated"
           @vnode-unmounted="analyticsColsUnmounted">

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Summary</h5>
              <AnalyticsNoData :isLoading="userReportSummaryLoading"/>

              <ul v-if="!userReportSummaryLoading" class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4" v-if="userReportSummary.page_views >= 0">{{ fmtInt(userReportSummary.page_views) }}</strong>
                    <strong class="h4" v-else-if="!userReportSummaryLoading">-</strong>
                  </div>
                  Slide views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4" v-if="userReportSummary.resource_views >= 0">{{ fmtInt(userReportSummary.resource_views) }}</strong>
                    <strong class="h4" v-else-if="!userReportSummaryLoading">-</strong>
                  </div>
                  File views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4" v-if="userReportSummary.shared_items >= 0">{{ fmtInt(userReportSummary.shared_items) }}</strong>
                    <strong class="h4" v-else-if="!userReportSummaryLoading">-</strong>
                  </div>
                  Shared items
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Presentations</h5>
              <div class="row gx-2 py-2">
                <!--<div class="offset-5 col-3 text-center text-muted">Est duration
                  <ScIcon name="questionCircle" v-sc-tooltip="'Estimated minutes spent viewing this presentation. Calculated hourly'"/>
                  </div>
                  NOTE: if uncommenting, remove offset-8 from next line-->
                    <div class="offset-8 col-2 text-end text-center text-muted">File views</div>
                <div class="col-2 text-end text-center text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="userReportTopShowcasesLoading" :noData="userReportTopShowcases.length === 0"/>

              <div v-if="!userReportTopShowcasesLoading && userReportTopShowcases && userReportTopShowcases.length > 0"
                   class="list-group list-group-flush">
                <div v-for="(entry, index) in userReportTopShowcasesForPage" :key="'rt' + index"
                     class="list-group-item">
                  <div class="row">
                    <div class="col-5">
                      <router-link
                          :to="{ name: 'reporting-analytics',
                                 params: { workshopId: workshopId },
                                 query: { uid: userId, scid: entry.id, start: ajaxParams.startDate, end: ajaxParams.endDate }}">
                        <ScIcon name="chartBarFW" class="me-2"/>
                        <span>{{ entry.name }}</span>
                      </router-link>
                    </div>
                    <!--<div class="col-3 text-center">
                      <span>{{ fmtDuration(entry.sum_duration) }}</span>
                      <span v-if="fmtDuration(entry.sum_duration)" class="text-muted fw-normal">min</span>
                    </div>
                    NOTE: if uncommenting, remove offset-3 from next line-->
                    <div class="offset-3 col-2 text-center">{{ fmtInt(entry.resource_count) }}</div>

                    <div class="col-2 text-center">{{ fmtInt(entry.page_count) }}</div>
                  </div>
                </div>
              </div>

              <div v-if="!userReportTopShowcasesLoading" class="row g-3">
                <div class="col-12">
                  <div v-if="userReportTopShowcases.length > 10" class="float-end">
                    <AnalyticsPaginator :total="userReportTopShowcases.length"
                                        :currentStart="userReportTopShowcasesStart"
                                        @update-start="updateReportTopShowcasesStart"></AnalyticsPaginator>
                  </div>
                  <button @click.prevent="exportTopShowcasesCsv()" class="btn btn-link text-muted"
                          v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Details and timelines</h5>

              <AnalyticsNoData :isLoading="userEventListLoading" :noData="!userEventList || userEventList.length === 0"/>

              <ul v-if="!userEventListLoading && userEventList && userEventList.length > 0" class="list-group list-group-flush">
                <li v-for="(item, idx) in userEventList" :key="'evIt-'+ idx" class="list-group-item">
                  <AnalyticsEventItem
                      :item="item" :workspaceId="mainMxCurrentWorkshopId"
                      :headerImg="mainMxCurrentWorkshop.header_img"
                      :userCanPerformListFiles="mainMxUwCanPerform('a:list_files')"
                      @open-res-info-popover="mainMxShowResInfo($event)">
                    <template v-slot:sharedUserLink>
                      <router-link v-if="item.shared && item.shared.shared_user"
                                   :to="{ name: 'reporting-sharing-history',
                                          params: { workshopId: mainMxCurrentWorkshopId },
                                          query: { uid: item.shared.shared_user.id }}">
                        <template v-if="item.shared.shared_user.name">{{item.shared.shared_user.name}}</template>
                        <template v-else-if="item.shared.shared_user.email">{{item.shared.shared_user.email}}</template>
                      </router-link>
                    </template>
                  </AnalyticsEventItem>
                </li>
              </ul>

            </div>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
    import $ from 'jquery';
    import Masonry from 'masonry-layout';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsPaginator from './AnalyticsPaginator.vue';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import AnalyticsEventItem from '../../../shared/analytics/AnalyticsEventItem.vue';
    import MainAppMixin from '../../MainAppMixin';

    let _testNotEnoughData = false;

    let DataManager = function() {
      return {

        getUserSummary(ajaxParams, vm) {
          if (vm.userReportSummaryLoading) return;
          vm.userReportSummary = {};
          vm.userReportSummaryLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_user_summary",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data)
              vm.userReportSummary = data;

          }).always(() => {
            vm.userReportSummaryLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading user summary');
          });
        },

        getUserEventList(ajaxParams, vm) {
          if (vm.userEventListLoading) return;
          vm.userEventList.splice(0);
          vm.userEventListLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_analytics_items_for_user",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.feed_items || data.feed_items.length > 0)
              vm.userEventList = data.feed_items;

          }).always(() => {
            vm.userEventListLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading user analytics');
          });
        },

        getUserReportTopShowcases(ajaxParams, vm) {
          if (vm.userReportTopShowcasesLoading) return;
          vm.userReportTopShowcasesStart = 0;
          vm.userReportTopShowcases.splice(0);
          vm.userReportTopShowcasesLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_user_showcases",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data || data.showcase_views)
              vm.userReportTopShowcases = data.showcase_views;

          }).always(() => {
            vm.userReportTopShowcasesLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading user presentations');
          });

            }
        };
    };
    let _dataManager = null;

    export default {
      name: "AnalyticsForUser",
      mixins: [ AnalyticsMixin, MainAppMixin ],
      components: { AnalyticsEventItem, AnalyticsPaginator, AnalyticsNoData, ScIcon },
      props: {
        ajaxParams: {}
      },
      data () {
        return {
          userEventList: [],
          userEventListLoading: false,
          userReportSummary: {},
          userReportSummaryLoading: false,
          userReportTopShowcases: [],
          userReportTopShowcasesLoading: false,
          userReportTopShowcasesStart: 0,
        };
      },
      mounted () {
        _dataManager = new DataManager();
        this.loadData();

      },

      methods: {
        analyticsColsMounted () {
          this.$msnry = new Masonry( this.$refs.analyticsColumns, {
            itemSelector: '.analytics-grid-item',
            percentPosition: true
          });
        },
        analyticsColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        analyticsColsUnmounted () {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        },
        paginated () { // triggered masonry update after pagination
          this.$nextTick(() => {
            this.analyticsColsUpdated();
          });
        },
        loadData () {
          _dataManager.getUserSummary(this.ajaxParams, this);
          _dataManager.getUserEventList(this.ajaxParams, this);
          _dataManager.getUserReportTopShowcases(this.ajaxParams, this);
        },
        updateReportTopShowcasesStart (v) {
          this.userReportTopShowcasesStart = v;
          this.paginated();
        },
        exportTopShowcasesCsv () {
          let csvLinesArr = [];
          for (let entry of this.userReportTopShowcases) {
            csvLinesArr.push([
              entry.name,
              //this.fmtDuration(entry.sum_duration),
              this.fmtInt(entry.resource_count),
              this.fmtInt(entry.page_count)
            ]);
          }
          // remove all whitespaces and lowercase
          let csvName = 'top-presentations' + ((this.userDisplayName) ? '-' + this.userDisplayName.replace(/\s+/g, '-').toLowerCase() : '');
          this.exportCsv(csvName, ['Presentation name',
            //'Estimated duration mins',
            'File views', 'Slide views'], csvLinesArr);
        }
      },
      computed: {
        user () {
          return this.userReportSummary.user;
        },
        userId () {
          return this.$route.query.uid;
        },
        userDisplayName () {
          return this.user ? this.user.display_name + (this.user.deleted_at ? ' (removed)' : '') : '';
        },
        workshopId () {
          return this.mainMxCurrentWorkshopId;
        },
        userReportTopShowcasesForPage() {
          if (!this.userReportTopShowcases) return [];
          return this.userReportTopShowcases.filter((entry, index) => {
            return index >= this.userReportTopShowcasesStart && index < (this.userReportTopShowcasesStart+10);
          });
        }
      },
    }
</script>
