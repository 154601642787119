<template>
   <div class="d-inline-flex">
     <div class="input-group">
       <ScIcon name="calendarAltFW" class="text-muted input-group-text px-2 small" :class="[disabled ? 'disabled': '']"/>

       <input ref="scflatpickr" class="form-control date-picker date-range px-2 text-center"
              type="text" placeholder="Date from" style="width: 265px;"
              :disabled="disabled">
     </div>
   </div>
</template>

<script>

    /*
    *   dateFrom ALWAYS has to be before dateTo
    *
    *   if difference between dateFrom and dateTo > 365 days -> adjust not selected date to match this criteria
    *       (eg. dateTo = 1.1.2020 > set dateFrom to be 1.1.2018 > adjust dateTo to be 1.1.2019)
    *
    *   date format: 10 Apr 2020
    *
    *   maxDate cannot be bigger than today
    *   max distance between minDate and maxDate = 3 years
    *
    *   validate date range provided from route (Analytics)
    *
    */

    import ScIcon from '../../shared/common/ScIcon.vue';
    import {subYears, differenceInCalendarDays, addDays, subDays, isSameDay} from 'date-fns';
    import flatpickr from 'flatpickr';

    let _fpFrom = null;
    let _fpTo = null;

    export default {
      name: "ScReportingRangeDatePicker",
      emits: ['select-date-range'],
      components: {ScIcon},
      props: {
        format: {type: String, default: "d M Y"},
        defaultDate: {type: Date, default: null},
        disabled: {type: Boolean, default: false},

        routeDateFrom: {type: Date, default: null},
        routeDateTo: {type: Date, default: null}
      },
      data () {
        return {
          minStartDate: subYears(new Date(), 3),
          maxEndDate: new Date(),
          dateTo: new Date(),
          dateFrom : subDays(new Date(), 30),
        }
      },
      mounted () {
        if (this.validDateRangeRoute) {
          this.dateFrom = this.routeDateFrom;
          this.dateTo = this.routeDateTo;
        }
        _fpFrom = flatpickr(this.$refs.scflatpickr, {
          mode: 'range',
          dateFormat: this.format,
          minDate: this.minStartDate,
          maxDate: this.maxEndDate,
          defaultDate: [this.dateFrom, this.dateTo],

          onChange: (selectedDates) => {
            //console.log('onChange FROM', selectedDates);
            if (selectedDates.length !== 2) return;
            this.dateFrom = selectedDates[0];
            this.dateTo = selectedDates[1];

            if (differenceInCalendarDays(this.dateTo, this.dateFrom) > 365) {
              // ensure the max diff is one year
              this.dateTo = addDays(this.dateFrom, 365);
            }

            // reset if end date before start date
            if (!this.validDateRange) {
              this.dateFrom = subDays(new Date(), 30);
              this.dateTo = new Date();
            }

            this.$emit('select-date-range', [this.dateFrom, this.dateTo])
          }
        });
      },
      beforeUnmount () {
        //console.log('destroy')
        if (_fpFrom) _fpFrom.destroy();
        if (_fpTo) _fpTo.destroy();
      },
      computed: {
        validDateRange () {
          return (
              this.dateTo.getTime() > this.dateFrom.getTime()
                || isSameDay(this.dateTo, this.dateFrom)
          );
        },
        validDateRangeRoute () {
          // check provided range from route is valid
          return (this.routeDateFrom && this.routeDateTo &&
              (
                  this.routeDateTo.getTime() > this.routeDateFrom.getTime()
                    || isSameDay(this.routeDateTo, this.routeDateFrom)
              )
              && (
                  this.routeDateTo.getTime() <= this.maxEndDate.getTime()
                    || isSameDay(this.routeDateTo, this.maxEndDate)
              )
              && (
                  this.routeDateFrom.getTime() >= this.minStartDate.getTime()
                    || isSameDay(this.routeDateFrom, this.minStartDate)
              )
              && differenceInCalendarDays(this.routeDateFrom, this.routeDateTo) <= 365);
        },
      },
    }

</script>
