<template>
  <ScNormalPageLayout headingPart1="Activity" headingPart2="Log" submenu="reporting">
    <div class="container mb-5 mt-3 mt-sm-5 sc-setting-email-container">
      <div class="row justify-content-center">
        <div class="col-11 col-sm-4 col-lg-3 pe-sm-0 pb-2">
          <form action="">
            <div class="mb-3">
              <label>Date range
                <ScIcon name="questionCircle" class="text-muted"
                        v-sc-tooltip="'Max range between dates is 1 year. Showcase retains data for the past 3 years; data older than this is automatically deleted.'"/>
              </label>
              <div class="justify-content-between d-flex">
                <ScReportingRangeDatePicker @select-date-range="selectDateRange" :disabled="loadingItems"/>
              </div>
            </div>

            <div class="mb-3">
              <div class="customSelectArrow">
                <label>Type</label>
                <select class="form-control bg-white" v-model="selectedActivityKey" @change="fetchFilteredData" :disabled="loadingItems">
                  <option value="">All types</option>
                  <option v-for="activityType in loadedActivityTypes" :value="activityType.key" :key="activityType.key">{{ activityType.description }}</option>
                </select>
              </div>

              <small class="d-block mt-2">
                <ScIcon name="questionCircle" class="text-muted"/> Sharing events are displayed in
                  <router-link :to="{ name: 'reporting-sharing-history',
                               params: { workshopId: mainMxCurrentWorkshopId }}">Sharing History</router-link>
              </small>
            </div>

            <div class="mb-3 clearfix" v-if="prepUsers.length > 0 && mainMxUwCanPerform('a:list_any_activity')">
              <label>Group / User</label>
              <ScSelectWithSearchAndGroups v-if="loadedGroups.length > 0 || loadedUsers.length > 0"
                                :disabled="loadingItems"
                                :items="[loadedGroups, loadedUsers]"
                                :labels="['Select a group', 'Select a user']"
                                :placeholder="preselectedUser"
                                reset="All groups and users"
                                @sc-select-item="selectUserOrGroupCallback" >
              </ScSelectWithSearchAndGroups>
            </div>

            <div class="mb-3 clearfix">
              <label>Presentation</label>
              <ScSelectWithThumbnailAndSearch v-if="loadedPresentations.length > 0"
                                :disabled="loadingItems"
                                :items="loadedPresentations"
                                :placeholder="preselectedPres"
                                reset="All presentations"
                                @sc-select-item="selectPresentationCallback"
                                @deselect-item="selectPresentationCallback" >
              </ScSelectWithThumbnailAndSearch>
            </div>

            <div class="mb-3">
              <div class="customSelectArrow">
                <label>Sort by</label>
                <select class="form-control bg-white" v-model="selectedOrder" @change="fetchFilteredData" :disabled="loadingItems">
                  <option selected value="desc">Newest first</option>
                  <option value="asc">Oldest first </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="col-11 col-sm-8 col-lg-9">
          <div class="activity-container px-0 px-sm-3 py-3">

            <div v-if="!loadingItems" class="mb-4 row justify-content-between">
              <div class="col-12 col-sm-9 col-lg-10 order-sm-1 order-2">
                <p v-if="activityItems.length > 0">
                  {{activityItems.length}}
                  {{activityItems.length === 1 ? 'record found' : 'records found'}}
                </p>
                <div v-if="activityItems.length === 0" class="text-center pt-5">
                  <em class="text-muted">No records found</em>
                </div>
                <div v-if="activityItems.length >= 1000" class="alert alert-warning">
                  More than 1,000 records found.  Only the first 1,000 are displayed, please filter.
                </div>
              </div>
              <div class="col-12 col-sm-3 col-lg-2 text-end order-sm-2 order-1">
                <button class="btn btn-link text-muted" @click.prevent="exportCsv"
                        :disabled="activityItems.length === 0"
                        v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
              </div>
            </div>

            <ActivityListItem v-for="activityItem in activityItems"
                              v-bind="activityItem" :key="activityItem.id"></ActivityListItem>

            <div v-show="loadingItems">
              <div class="col-12 text-center pt-5">
                <ScIcon name="spinnerMedium" class="text-muted"/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ScNormalPageLayout>
</template>

<script>

    import $ from 'jquery';
    import _ from 'underscore'; // findWhere
    import {format, subDays} from 'date-fns';
    import ScReportingRangeDatePicker from './ScReportingRangeDatePicker.vue';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ActivityListItem from './ActivityListItem.vue';
    import ScSelectWithSearchAndGroups from '../global/ScSelectWithSearchAndGroups.vue';
    import ScSelectWithThumbnailAndSearch from '../../shared/common/ScSelectWithThumbnailAndSearch.vue';
    import MainAppMixin from '../MainAppMixin';
    import Papa from 'papaparse';
    import ScCsvUtil from "../global/ScCsvUtil";


    let DataManager = {
      fetchFilteredActivityData (vm) {
        if (vm.loadingItems)
          return;
        vm.loadingItems = true;

        $.ajax({
          method: 'GET',
          url: '/main/activity/ajax_activity_list',
          data:  {
            workspace_id: vm.mainMxCurrentWorkshopId,
            activity_type: vm.selectedActivityKey,
            presentation_id: vm.selectedPresentationId,
            user_id: vm.selectedUserId,
            group_id: vm.selectedGroupId,
            start_date: vm.startDate.toISOString(),
            end_date: vm.endDate.toISOString(),
            sort: vm.selectedOrder
          }
        }).done(function (data) {
          //console.log('fetchFilteredActivityData', data);
          vm.prepActivityItems(data.activity_items);

        }).always(function () {
          vm.loadingItems = false;

        }).fail(function (jqXhr) {
          ScNotification.growlXhrError(jqXhr, 'fetching activity');
        });
      },
      fetchParamsData (vm) {
        $.ajax({
          type: 'GET',
          url: '/main/activity/ajax_activity_and_forms_params',
          data: {
            workspace_id: vm.mainMxCurrentWorkshopId
          }
        }).done(function (data) {
          //console.log(data, 'fetchParamsDAta');
          if (data.types) {
            vm.loadedActivityTypes = data.types;
            vm.prepUsers(data.users);
            vm.prepGroups(data.groups);
            vm.prepPres(data.presentations);
          }
        }).fail(function (jqXhr) {
          ScNotification.growlXhrError(jqXhr, 'fetching options');
        });
      }
    };

    let _getActivityText = (item) => {
      //console.log('item', item.activity_key === 'download_resource', item.resource && item.shared);
      let activityText = item.activity_text;
      let sharedDisplayName = '';
      if (item.shared) {
        if (item.shared.email && item.shared.email.indexOf('anonymous-recipient-') === 0 ) item.shared.email = null;
        if (item.shared.email && item.shared.name) sharedDisplayName = `${item.shared.name} <${item.shared.email}>`;
        else if (item.shared.name) sharedDisplayName = item.shared.name;
        else if (item.shared.email) sharedDisplayName = item.shared.email;
      }

      if (item.activity_key === 'email_bounced' && item.shared && item.user && item.sent_email) {
        activityText = `${item.activity_text} for email to [${sharedDisplayName}] with subject [${item.sent_email.subject}]`;
      }
      else if (item.activity_key === 'email_bounced' && item.user && item.sent_email) {
        activityText = `${item.activity_text} for email to [${item.sent_email.to_email}] with subject [${ item.sent_email.subject}]`;
      }
      return activityText;
    };

    export default {
      name: "Activity",
      mixins: [MainAppMixin],
      components: {ScNormalPageLayout, ActivityListItem, ScSelectWithSearchAndGroups, ScReportingRangeDatePicker,
        ScIcon, ScSelectWithThumbnailAndSearch},
      data () {
        return {
          startDate: subDays(new Date(), 30),
          endDate: new Date(),

          selectedPresentationId: 0,
          selectedUserId: 0,
          selectedGroupId: 0,
          selectedActivityKey: '',
          selectedOrder: 'desc',

          loadingItems: false,
          loadedUsers: [],
          loadedGroups: [],
          loadedPresentations: [],
          loadedActivityTypes: [],
          activityItems: [],
        };
      },
      mounted () {
        DataManager.fetchParamsData(this);
        this.checkHash();
      },
      methods: {
        selectDateRange (dateArr) {
          //console.log('date range', dateArr)
          this.startDate = dateArr[0];
          this.endDate = dateArr[1];
          this.fetchFilteredData();
        },
        checkHash () {
          if (this.$route.query.uid)
            this.selectedUserId = Number(this.$route.query.uid);

          if (this.$route.query.scid)
            this.selectedPresentationId = Number(this.$route.query.scid);

          DataManager.fetchFilteredActivityData(this);
        },
        formatUserName (user) {
          return `${user.display_name} ${(user.display_name !== user.email ? ' <' + user.email + '>' : '')}  ${this.isDeleted(user)}`;
        },
        isDeleted (user) {
          let delDate = this.mainMxScDateFmt(user.deleted_at);
          return delDate ? ' (deleted ' + delDate + ')' : '';
        },
        prepUsers (data) {
          for (let u of data) {
            u.showName = this.formatUserName(u);
          }
          this.loadedUsers.splice(0);
          this.loadedUsers = data;
        },
        prepGroups (data) {
          for (let g of data) {
            g.showName = g.name;
          }
          this.loadedGroups.splice(0);
          this.loadedGroups = data;
        },
        prepPres (data) {
          for (let p of data) {
            p.showName = p.title;
          }
          this.loadedPresentations.splice(0);
          this.loadedPresentations = data;
        },
        prepActivityItems (data) {
          for (let i of data) {
            i.activity_key = i.key;
            i.generatedActivityText = _getActivityText(i);
          }
          this.activityItems.splice(0);
          this.activityItems = data;
        },
        selectUserOrGroupCallback (cb) {
          this.selectedUserId = 0;
          this.selectedGroupId = 0;

          if (cb && cb.display_name)
            this.selectedUserId = cb.id;
          else if (cb)
            this.selectedGroupId = cb.id;

          this.fetchFilteredData();
        },
        selectPresentationCallback (presId) {
          //console.log('cb', presId)
          if (presId === null)
            this.selectedPresentationId = 0;
          else
            this.selectedPresentationId = presId;
          this.fetchFilteredData();
        },
        fetchFilteredData () {
          DataManager.fetchFilteredActivityData(this);
        },
        exportCsv () {
          let csvData = [['Date', 'Type', 'User', 'Description']];
          for (let ai of this.activityItems) {
            let activityType = _.findWhere(this.loadedActivityTypes, {key: ai.key});
            let cells = [
              format(new Date(ai.activity_date), 'yyyy-MM-dd hh:mm:ss a'),  // csv friendly datetime
              activityType ? activityType.description : ai.key,
              ai.user ? ai.user.email : '',
              ai.generatedActivityText
            ];
            csvData.push(cells);
          }
          let finalCsvText = Papa.unparse(csvData);
          let fileName = `activity-${format(new Date(), 'yyyyMMddHHmm')}.csv`;
          ScCsvUtil.deliverCsv(finalCsvText, fileName);
        }
      },
      computed: {
        preselectedUser () {
          if (this.selectedUserId !== 0 && this.loadedUsers) {
            let user = _.findWhere(this.loadedUsers, {id: this.selectedUserId});
            if (user)
              return user.showName;
          }
          return 'All groups and users';
        },
        preselectedPres () {
          if (this.selectedPresentationId !== 0 && this.loadedPresentations) {
            let pres = _.findWhere(this.loadedPresentations, {id: this.selectedPresentationId});
            if (pres)
              return pres.showName;
          }
          return 'All presentations';
        }
      }
    }
</script>

<style scoped>

  .activity-container {
    min-height: 600px;
    border-top: none;
    border-left: 1px solid #e9e9f2;
  }
  @media (max-width: 576px) {
    .activity-container {
      border-left: none;
      border-top: 1px solid #e9e9f2;
    }
  }

</style>