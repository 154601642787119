/**
 *
 * Reporting
 *
 *
 */

import $ from "jquery";
import _ from 'underscore'; // isArray, findWhere
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
import ScNotification from "../common/ScNotification.vue";


let _state = {
    rshIsOnline: true,
    allRecipients: [],
    analyticsEventItems: [],
    analyticsEventItemIds: [],
    offsetForRecipients: 0,
    offsetForAnalyticsEventItems: 0,
    moreRecipientsAvailable: false,
    moreAnalyticsEventsAvailable: false,
};



let _getters = {
    // should always start with "rsh" to avoid collisions with other modules
};


let _actions = {
    // should always start with "rsh" to avoid collisions with other modules

    rshLoadAnalyticsEventItems: (context, args) => {
        return new Promise((resolve) => {
            $.ajax({
                type: 'GET', url: ViewerEmbedHandler.ajaxWrapUrl('/main/analytics/ajax_get_analytics_items_for_shared'),
                data: { shared_user_id: args.sharedUserId, offset: args.offset,
                    workspace_id: args.workspaceId},
                beforeSend: ViewerEmbedHandler.ajaxBeforeSend
            }).done(function(data){
                //console.log('loadAllAnalyticsItems', data.feed_items, data.offset_limit);
                if (data && data.feed_items)
                    context.commit('rshUpdateAnalyticsEventItems', data);

                resolve()
            }).fail(function(jqXhr, status, error){
                console.error('rshLoadAnalyticsEventItems error', status, error);
                ScNotification.growlXhrError(jqXhr, 'getting analytics items');
                resolve();
            });
        });
    },
    rshLoadSharingHistoryRecipients: (context, args) => {
        return new Promise((resolve) => {
            $.ajax({
                type: 'GET', url: ViewerEmbedHandler.ajaxWrapUrl('/main/analytics/ajax_get_sharing_history_recipients'),
                data: {search: args.search, offset: args.offset, workspace_id: args.workspaceId},
                beforeSend: ViewerEmbedHandler.ajaxBeforeSend
            }).done(function(data){
                //console.log('rshLoadSharingHistoryRecipients', data);
                if (data && data.shared_users)
                    context.commit('rshUpdateAllRecipients', data);
                resolve();
            }).fail(function(jqXhr, status, error){
                console.error('rshLoadSharingHistoryRecipients error', status, error);
                ScNotification.growlXhrError(jqXhr, 'getting past recipients');
                resolve();
            });
        });
    },
    rshRenameSharedUser(context, args) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST', url: ViewerEmbedHandler.ajaxWrapUrl('/main/sharing/ajax_rename_shared_user'),
                data: { shared_user_id: args.recipientId, name: args.newName, workspace_id: args.workshopId },
                beforeSend: ViewerEmbedHandler.ajaxBeforeSend
            }).done(function() {
                resolve();
            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'renaming sharing recipient');
                reject();
            });
        });
    },
    rshDelSharedItem(context, args) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST', url: ViewerEmbedHandler.ajaxWrapUrl('/main/sharing/ajax_del_shared_items_v2'),
                data: { shared_user_id: args.recipientId, workspace_id: args.workshopId },
                beforeSend: ViewerEmbedHandler.ajaxBeforeSend
            }).done(function() {
                resolve();
            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'deleting shared events');
                reject()
            });
        });
    }
};


let _mutations = {
    // should always start with "rsh" to avoid collisions with other modules

    rshOnline: (state, rshIsOnline) => {
        state.rshIsOnline = rshIsOnline;
    },
    rshUpdateAnalyticsEventItems: (state, data) => {
        if (data.feed_items && data.feed_items.length > 0 && data.feed_items.forEach) {
            data.feed_items.forEach((item) => {
                if (!state.analyticsEventItemIds[String(item.id)]) {
                   state.analyticsEventItemIds.push(String(item.id));
                   state.analyticsEventItems.push(Object.freeze(item));  // freeze object, doesn't need to be reactive
                }
            });
        }
        state.moreAnalyticsEventsAvailable = data.feed_items.length >= data.offset_limit;
        state.offsetForAnalyticsEventItems += data.offset_limit;
    },
    rshUpdateAllRecipients: (state, data) => {
        if (_.isArray(data.shared_users)) {
            data.shared_users.forEach((item) => {
                if (!_.findWhere(state.allRecipients, {id: item.id})) {
                    state.allRecipients.push(item);
                }
            });
        }
        state.moreRecipientsAvailable = data.shared_users.length >= data.offset_limit;
        state.offsetForRecipients += data.offset_limit;
    },
    rshResetAllRecipients: (state) => {
        state.allRecipients.splice(0)
    },
    rshResetAnalyticsEventItems: (state) => {
        state.analyticsEventItems.splice(0);
        state.analyticsEventItemIds.splice(0);
    },
    rshResetOffsetForRecipients: (state) => {
        state.offsetForRecipients = 0;
        state.moreRecipientsAvailable = false;
    },
    rshResetOffsetAnalyticsEventItems: (state) => {
        state.offsetForAnalyticsEventItems = 0;
        state.moreAnalyticsEventsAvailable = false;
    },
};


export default {
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};

